import React from "react";

import { OnChange } from "@decorators/onchange";
import { Set } from "@decorators/set";

import ClipEffect from "@models/clipeffect";
import Range from "@clip/properties/range";
import { getSubtitleManager } from "@providers/subtitlemanager";
import Select, { SelectOption } from "@clip/properties/select";
import Color from "@clip/properties/color";
import MultiNumberProperty from "@clip/properties/multinumber";

const borderStyles : SelectOption[] = [
    {
        value: "none",
        i18nLabel: "common:border_styles.none"
    },
    {
        value: "solid",
        i18nLabel: "common:border_styles.solid"
    },
    {
        value: "dotted",
        i18nLabel: "common:border_styles.dotted"
    },
    {
        value: "dashed",
        i18nLabel: "common:border_styles.dashed"
    },
    {
        value: "double",
        i18nLabel: "common:border_styles.double"
    },
    {
        value: "groove",
        i18nLabel: "common:border_styles.groove"
    },
    {
        value: "ridge",
        i18nLabel: "common:border_styles.ridge"
    },
    {
        value: "inset",
        i18nLabel: "common:border_styles.inset"
    },
    {
        value: "outset",
        i18nLabel: "common:border_styles.outset"
    }
];

export default class BorderEffect extends ClipEffect {
    public type = "BorderEffect";

    @Set({ i18nKey: "pages.project.property_name.border_radius", max: 50 })
    @OnChange((effect: BorderEffect) => {
        let subtitleElement = effect.clip.findSubtitleElement();
        effect.createElements(subtitleElement);
    })
    public borderRadius: Range = new Range(this);

    @Set({ i18nKey: "pages.project.property_name.border_style", options: borderStyles })
    @OnChange((effect: BorderEffect) => {
        let subtitleElement = effect.clip.findSubtitleElement();
        effect.createElements(subtitleElement);
    })
    public borderStyle: Select = new Select(this);

    @Set({ i18nKey: "pages.project.property_name.border_color"})
    @OnChange((effect: BorderEffect) => {
        let subtitleElement = effect.clip.findSubtitleElement();
        effect.createElements(subtitleElement);
    })
    public borderColor: Color = new Color(this);

    @Set({ i18nKey: "pages.project.property_name.border_width", min:0, max: 10})
    @OnChange((effect: BorderEffect) => {
        let subtitleElement = effect.clip.findSubtitleElement();
        effect.createElements(subtitleElement);
    })
    public multiBorderWidth : MultiNumberProperty = new MultiNumberProperty(this);

    constructor(){
        super();

        this.borderRadius.value = 20;
        this.borderStyle.value = "solid";
        this.borderColor.value = "#000000";

        this.multiBorderWidth.values = [
            {
                key: "border-left-width",
                label: "Left",
                value: 3
            },
            {
                key: "border-top-width",
                label: "Top",
                value: 3
            },
            {
                key: "border-right-width",
                label: "Right",
                value: 3
            },
            {
                key: "border-bottom-width",
                label: "Bottom",
                value: 3
            },
        ]
    }

    public init() {
        super.init();
    }

    public exportPayload(){
        let payload = super.exportPayload();

        if (! this.clip.hasEffect("TitleEffect")){
            payload.data.borderStyle      = this.borderStyle.exportPayload();
            payload.data.borderColor      = this.borderColor.exportPayload();
            payload.data.multiBorderWidth = this.multiBorderWidth.exportPayload();
        }
        payload.data.borderRadius = this.borderRadius.exportPayload();

        return payload;
    }

    public getPropertyEditorComponents(){
        return (
            <this.PropertyEditorWrapperComponent property={this}>
                { (!this.clip.hasEffect("TitleEffect")) && <>
                    { this.borderStyle.getPropertyEditorComponent() }
                    { this.borderColor.getPropertyEditorComponent() }
                    { this.multiBorderWidth.getPropertyEditorComponent() }
                </>}
                { this.borderRadius.getPropertyEditorComponent() }
            </this.PropertyEditorWrapperComponent>
        )
    }

    public createElements(subtitleElement: JQuery<HTMLElement>) {
        const [ subtitleManager ] = getSubtitleManager();
        const content = subtitleElement.find(".subtitle-content");

        if (this.clip.hasEffect("TitleEffect")){
            subtitleElement.find(".mainbg")
                .css("border-radius", this.borderRadius.value/100 * Math.min(subtitleElement.width(), subtitleElement.height()));

        } else {
            this.multiBorderWidth.values.map((v) => {
                content.css(v.key, v.value);
            });

            content
                .css("border-radius", this.borderRadius.value/100 * Math.min(subtitleElement.width(), subtitleElement.height()))
                .css("border-style", (this.borderStyle.value === "") ? "none" : this.borderStyle.value)
                .css("border-color", this.borderColor.value);

            if (content.css("padding") === "0px"){
                content.css("padding", "2px");
            }
        }
    }
}

import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import $ from "jquery";
import { convert as HtmlToText } from "html-to-text";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faEllipsisVertical, faTrash } from "@fortawesome/free-solid-svg-icons";
import { ControlledMenuProps } from "@szhsin/react-menu";

import { arrayFindClosest } from "@utils/array";

import { useProject } from "@providers/project";
import { useVideoPlayer } from "@providers/videoplayer";
import ChannelModel, { ChannelType } from "@models/channel";

import ClipModel from "@models/clip/clip";
import TextClip from "@models/clip/textclip";
import ImageClip from "@models/clip/imageclip";
import ActionClip from "@models/clip/actionclip";

import SubtitleEditorService from "@services/SubtitleEditorService";
import KeyService from "@services/KeyService";
import ClipboardService from "@services/ClipboardService";
import ClipCopyHandler from "@services/clipboard_handlers/clip_copy_handler";

import TimelineClipChangeCommand from "@commands/timeline_clip_change";

import { useTimelineContext } from "./timeline";
import { ControlledMenu } from "@components/menu/menu";

import svgFx from "@images/project/fx.svg";
import Tooltip from "@components/tippy/tooltip";
import MarkerClip from "@models/clip/markerclip";

type ClipProps = {
    channel: ChannelModel;
    clip: ClipModel;
    className?: string;
}

type DraggingData = {
    moved: boolean;
    clone?: HTMLDivElement;
    offsetX?: number;
    offsetY?: number;
    direction?: "left" | "right";
    newDuration?: number;
    newChannel?: ChannelModel;
    newStartTime?: number;
    originalStartTime?: number;
    originalDuration?: number;
    snapPoints?: number[];
}


const Clip = ({ clip, className }: ClipProps) => {
    const { t } = useTranslation([ "mve", "common" ]);
    const [project] = useProject();
    const [player] = useVideoPlayer();
    const { snapTolerance } = useTimelineContext();
    const clipElem = React.useRef<HTMLDivElement>();
    let [movingStartData, setMovingStartData] = useState<{ x: number, y: number, element: HTMLElement }>();
    const [movingEvent, setMovingEvent] = useState<DraggingData>();
    const [status, setStatus] = useState<"disabled" | "inactive">(null);
    const [menuOpened, setMenuOpened] = useState<ControlledMenuProps["anchorPoint"]>(null);

    const videoDuration = player.duration();

    const deleteClip = () => {
        clip.delete();

        if (clip.selected) {
            SubtitleEditorService.setTarget(null);
        }
    }

    const copyClip = () => {
        ClipboardService.set( new ClipCopyHandler(clip) );
    }

    const onMoveHandler = (e: JQuery.MouseMoveEvent) => {
        const target = e.target as HTMLDivElement;
        let { pageX, pageY } = e;
        
        const channelIndex = parseInt( target.getAttribute("channel-index") );
        let rect;
        let hoveredRect = target.getBoundingClientRect();
        
        if (clip.channel.type === ChannelType.MARKER && project.getChannelByIndex(channelIndex)?.type === ChannelType.MARKER){
            rect = target.getBoundingClientRect();
        } else if (clip.channel.type === ChannelType.NORMAL && project.getChannelByIndex(channelIndex)?.type === ChannelType.NORMAL) {
            rect = target.getBoundingClientRect();
        }

        let dummyChannel = $(".channel")[0] as HTMLDivElement;
        let channelWidth = [dummyChannel.getBoundingClientRect().left, dummyChannel.getBoundingClientRect().left + dummyChannel.getBoundingClientRect().width - clipElem.current.getBoundingClientRect().width];
        
        if (isTimelineMarker){
            channelWidth[1] = dummyChannel.getBoundingClientRect().left + dummyChannel.getBoundingClientRect().width;
        }

        if (clip.channel.type === ChannelType.MARKER){
            hoveredRect = dummyChannel.getBoundingClientRect();
        }

        
        let doSnap = ! KeyService.isPressing("ShiftLeft");
        
        // RESIZING
        if (movingEvent?.direction) {
            if(pageX < dummyChannel.getBoundingClientRect().left){
                return
            } else if (pageX > dummyChannel.getBoundingClientRect().left + dummyChannel.getBoundingClientRect().width){
                return
            }

            
            const { direction, originalStartTime, originalDuration, snapPoints } = movingEvent;

            const MIN_CLIP_DURATION = 1000;

            if (clip.channel.type === ChannelType.MARKER || (target && target.classList.contains("channel"))) {
                let newStartTime: number = undefined;
                let newDuration: number  = undefined;

                let timeAtMousePosition = ((pageX - hoveredRect.left) / hoveredRect.width) * videoDuration;

                if (direction === "left") {
                    newStartTime = Math.min(originalStartTime + originalDuration - MIN_CLIP_DURATION, timeAtMousePosition);
                    
                    if (doSnap) {
                        newStartTime = arrayFindClosest(snapPoints, newStartTime, snapTolerance) || newStartTime;
                    }
                    newDuration = originalDuration + (originalStartTime - newStartTime);
                
                } else {
                    let newEndTime = timeAtMousePosition;
                    
                    if (doSnap) {
                        newEndTime = arrayFindClosest(snapPoints, timeAtMousePosition, snapTolerance) || newEndTime;
                    }
                    newDuration = Math.max(MIN_CLIP_DURATION, newEndTime - originalStartTime);
                }

                if (newStartTime) {
                    clipElem.current.style.left  = `${newStartTime / videoDuration * 100}%`;
                }
                if (newDuration) {
                    clipElem.current.style.width = `${newDuration  / videoDuration * 100}%`;
                }

                setMovingEvent(v => {
                    v.moved        = true;
                    v.newStartTime = newStartTime;
                    v.newDuration  = newDuration;
                    return v;
                });
            }

        // MOVING
        } else {
            const { clone, offsetX, offsetY, snapPoints } = movingEvent;
    
            let targetChannel: ChannelModel = undefined;
            let startTime: number           = undefined;
            let canChangeChannel : boolean  = true;

            let x = pageX - offsetX;
            let y = pageY - offsetY;

            if (clip.channel.type === ChannelType.MARKER || (target && target.classList.contains("channel"))){
                targetChannel = project.getChannelByIndex(channelIndex);
                if (targetChannel){
                    if (clip.channel.type !== targetChannel.type){
                        canChangeChannel = false
                    }
                }
    
                let startPercentage = (x - hoveredRect.left) / hoveredRect.width;
                startTime           = Math.max(0, Math.min(videoDuration - clip.duration.value, videoDuration * startPercentage));
    
                if (doSnap) {
                    let clipLeftSnapPoint = arrayFindClosest(snapPoints, startTime, snapTolerance);
                
                    if (clipLeftSnapPoint) {
                        startTime = clipLeftSnapPoint;
                    
                    } else {
                        if (!(clip instanceof MarkerClip) || (clip instanceof MarkerClip && clip.isDurationEditable)){
                            let clipRightSnapPoint = arrayFindClosest(snapPoints, startTime + clip.duration.value, snapTolerance);
                        
                            if (clipRightSnapPoint) {
                                startTime = clipRightSnapPoint - clip.duration.value;
                            }
                        }
                    }
                }
                
                x = hoveredRect.left + ((startTime / videoDuration) * hoveredRect.width);
                y = rect?.top;
            }

            if (clip.channel.type === ChannelType.MARKER){
                clone.style.left = `${Math.max(channelWidth[0],Math.min(channelWidth[1] ,x))}px`;

                if (channelIndex && channelIndex != 0){
                    clone.style.top  = `${y}px`;
                }
            } else {
                clone.style.left = `${x}px`
                clone.style.top = `${y}px`
            }
            

            setMovingEvent(v => {
                v.moved        = true;
                v.newChannel   = canChangeChannel && targetChannel ? targetChannel : clip.channel;
                v.newStartTime = startTime;
                return v;
            })
        }
    }

    const onMoveEnd = () => {
        if (movingEvent?.moved) {
            const cmd = new TimelineClipChangeCommand(clip, {
                duration:  movingEvent.newDuration,
                startTime: movingEvent.newStartTime,
                channel:   movingEvent.newChannel
            });
            cmd.execute();
        }

        setStatus(null);
        movingEvent?.clone?.remove();

        $(".timeline-content .clip").removeClass("inactive");
        
        setMovingEvent(undefined);
    }

    const startMoving = (e: JQuery.MouseMoveEvent) => {
        // threshold
        if (
            Math.abs(e.pageX - movingStartData.x) < 10 &&
            Math.abs(e.pageY - movingStartData.y) < 10
        ) {
            return;
        }

        $(window).off("mousemove", startMoving);
        $(window).off("mouseup", interruptMoving);

        const target     = movingStartData.element;
        const isResizing = target.classList.contains("resize-dots");

        const snapPoints = player.getTimelineSnapPointsMS(true, [ clip ], snapTolerance);

        // RESIZING
        if (isResizing) {
            const direction = target.classList.contains("left") ? "left" : "right";
            
            setStatus("inactive");
            
            setMovingEvent({
                moved: false,
                direction,
                originalStartTime: clip.startTime,
                originalDuration: clip.duration.value,
                snapPoints
            });

        // MOVING
        } else {
            let rect = clipElem.current.getBoundingClientRect();

            let clone = clipElem.current.cloneNode(true) as HTMLDivElement;
            clone.classList.add("dragging");
    
            clone.style.left   = `${rect.left}px`;
            clone.style.top    = `${rect.top}px`;
            clone.style.width  = `${rect.width}px`;
            clone.style.height = `${rect.height}px`;
            clone.style.zIndex = "20000";
            
            window.document.body.appendChild(clone);
            
            let offsetX = movingStartData.x - rect.left;
            let offsetY = movingStartData.y - rect.top;

            if (isTimelineMarker && !clip.isDurationEditable){
                offsetX -= rect.width/2
            }
    
            setStatus("disabled");

            setMovingEvent({
                moved: false,
                clone,
                offsetX,
                offsetY,
                snapPoints
            });
        }

        $(".timeline-content .clip").addClass("inactive");
    }

    const interruptMoving = () => {
        $(window).off("mousemove", startMoving);
    }

    const onMouseDown = (e: any) => {
        if (e.button !== 0)
            return;

        project.setSelectedClip(clip);
        window.projectPageForceRerender();

        movingStartData = { x: e.pageX, y: e.pageY, element: e.target };
        setMovingStartData(movingStartData);

        $(window).on("mousemove", startMoving);

        $(window).one("mouseup", e => {
            interruptMoving();

            if (player.video.paused()) {
                SubtitleEditorService.setTarget(clip, [ player.subtitleManager.subtitlesHolderElement[0] ]);
            }
        });
    }

    useEffect(() => {
        if (movingEvent) {
            $(window).on("mousemove", onMoveHandler);
            $(window).on("mouseup", onMoveEnd);
        }
        
        return () => {
            $(window).off("mousemove", onMoveHandler);
            $(window).off("mouseup", onMoveEnd);
        };
    }, [ movingEvent ]);

    const isExactTimeActionMode = clip instanceof ActionClip;
    
    const isTimelineMarker = clip instanceof MarkerClip;

    
    useEffect(() => {
        if (isTimelineMarker){
            clip.updateVisuals();
        }
    })

    return <>
        <div
            ref={clipElem}
            data-id={clip.id}
            style={!(isTimelineMarker && clip.isPointMarker.value) ? {
                left:  `${clip.startTime / videoDuration * 100}%`,
                width: `${clip.duration.value  / videoDuration * 100}%`
            } : {
                left: `${clip.startTime / videoDuration * 100}%`,
                fontSize: "24px",
                transform: "translate(-50%, 0)",
                width: "fit-content",
                zIndex: "1",
                top: "-50%"
            }}
            
            className={classNames(
                "clip",
                `clip-${clip.type}`,
                status,
                className,
                {
                    "selected":               project.selectedClip === clip,
                    "exact-time-action-mode": isExactTimeActionMode,
                    "timeline-marker":        clip.channel.type === ChannelType.MARKER
                }
            )}
            onMouseDown={onMouseDown}
            onContextMenu={e => {
                e.stopPropagation();
                e.preventDefault();
                setMenuOpened({ x: e.clientX, y: e.clientY })
            }}
        >   
            
            {(clip.isDurationEditable && !(clip instanceof MarkerClip)) && <>
                <FontAwesomeIcon icon={faEllipsisVertical} className="resize-dots left"/>
                <FontAwesomeIcon icon={faEllipsisVertical} className="resize-dots right"/>
            </>}

            {clip instanceof MarkerClip && 
            <>
                <div className="resize-dots left"></div>
                <div className="resize-dots right"></div>

                <Tooltip maxWidth={"300px"} text={`<h4>${clip.title.value}</h4>${clip.description.value}`}>
                    <div className="point-marker"></div>
                </Tooltip>

                <div className="marker-clip-border"
                    style={(isTimelineMarker && clip.isPointMarker.value) ? {
                        borderRightWidth: "0px",
                        left: "50%"
                    } : {}}
                ></div>
            </>
            }

            {isExactTimeActionMode && (
                <div className="marker-pin"></div>
            )}

            <Tooltip disabled={!(isTimelineMarker && clip.isDurationEditable)} maxWidth={"300px"} text={clip instanceof MarkerClip ? clip.description.value : ""}>
                <div className="preview" style={(isTimelineMarker && clip.isPointMarker.value) ? {display: "none", pointerEvents: "none"} : {}}>
                    {(clip instanceof TextClip) && <>
                        <span>{ HtmlToText(clip.text.value) }</span>
                    </>}

                    {(clip instanceof ImageClip) && <>
                        <img src={clip.getImagePath()} alt={clip.path} />
                    </>}

                    {(clip instanceof MarkerClip) && <>
                        <span style={{fontSize: "12px"}}>{ HtmlToText(clip.title.value) }</span>
                    </>}
                    

                    <div className="icons">
                        {clip.hasEffect() && <>
                            <img src={svgFx} alt="fx" />
                        </>}
                    </div>
                </div>
            </Tooltip>
        </div>


        
        <ControlledMenu
            anchorPoint={menuOpened}
            opened={!!menuOpened}
            onClose={_ => setMenuOpened(null)}
            data={!(!isExactTimeActionMode && clip instanceof MarkerClip) ? [
                { title: t("pages.project.clip").toUpperCase(), header: true },
                { title: t("common:actions.copy"), icon: faCopy, onClick: copyClip },
                { title: t("common:actions.delete"), icon: faTrash, onClick: deleteClip }
            ] : [
                { title: "MARKER", header: true},
                { title: t("common:actions.delete"), icon: faTrash, onClick: deleteClip }
            ]}
        />
    </>;
};

export default Clip;

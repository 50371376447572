import $ from "jquery";
import _ from "lodash";

import AnimPlayType from "@enums/animplaytype";

import SubtitleEditorService from "@services/SubtitleEditorService";
import { getVideoPlayer } from "@providers/videoplayer";
import { getSubtitleManager } from "@providers/subtitlemanager";
import SetDecorator from "@decorators/set";

import { ClipBackendPayload } from "@models/project";
import Channel from "@models/channel";
import ClipEffect from "@models/clipeffect";

import BackgroundEffect from "@clip/effects/background";
import FadeOutEffect from "@clip/effects/fadeout";
import FadeInEffect from "@clip/effects/fadein";
import TitleEffect from "@clip/effects/title";
import TargetLineEffect from "@clip/effects/targetline";
import ListSlideUpEffect from "@clip/effects/list_slideup";
import CounterEffect from "@clip/effects/counter";
import LatexEffect from "@clip/effects/latex";
import UppercaseEffect from "@clip/effects/uppercase";
import BorderEffect from "@clip/effects/border";
import ShadowEffect from "@clip/effects/shadow";

import ClipDeleteCommand from "@services/commands/clip_delete";
import OnChangeDecorator, { OnChange } from "@decorators/onchange";
import { CSSProperties, mergeStyles } from "@utils/styles";
import NumberProperty from "@clip/properties/number";

export type ClipType = "TextClip" | "ImageClip" | "ShapeClip" | "MarkerClip" | "ActionClip";

export type ClipStyles = {
    parent?: CSSProperties;
    wrapper?: CSSProperties;
    content?: CSSProperties;
};

export default class Clip {
    @OnChange((clip: Clip) => {
        window.projectPageForceRerender();
    })
    public duration: NumberProperty = new NumberProperty(this);

    public channel: Channel;
    public id: number = 0;
    public type: ClipType = null;
    public startTime: number = 0;
    public isDurationEditable: boolean = true;
    public visualClip: boolean = true;

    public effects: ClipEffect[] = [];

    public visible: boolean = false;
    public selected: boolean = false;

    protected editableProperties: string[] = [];


    constructor(channel: Channel) {
        this.channel = channel;

        this.duration.value = 10000;
        this.duration.min = 1000;
        this.duration.i18nKey = "pages.project.property_name.duration"
        if (this.isDurationEditable){
            this.editableProperties.push("duration")
        }
    }

    destructor() {
        OnChangeDecorator.unsubscribe(this);
    }

    public exportPayload(): any {
        return {
            id:   this.id,
            type: this.type,
            data: {
                startTime: this.startTime,
                duration:  this.duration.value,
                effects:   this.effects.map(v => v.exportPayload())
            }
        };
    }

    public applyPayload(payload: ClipBackendPayload) {
        this.id              = payload.id;
        this.type            = payload.type;
        this.startTime       = payload.data.startTime;
        this.duration.value  = payload.data.duration;

        payload.data.effects.forEach(v => {
            if (v) {
                let effect = createClipEffectFromType(v.type);
                if (effect){
                    this.addEffect(effect);
        
                    effect.applyPayload(v);

                }
            }
        });
    }


    public init = () => {
        SetDecorator.execute(this);
    }


    public getProject = () => {
        return this.channel.project;
    }

    public delete() {
        let cmd = new ClipDeleteCommand(this);
        cmd.execute();
    }

    public setStartTime = (ms: number) => {
        this.startTime = parseFloat( Math.max(ms, 0).toFixed(3) );
    }

    public setDuration = (ms: number) => {
        this.duration.set(parseFloat( Math.max(ms, 1000).toFixed(3) ));
    }

    public setChannel = (channel: Channel) => {
        if (this.channel == channel)
            return false;

        if (this.selected) {
            SubtitleEditorService.setTarget(null);
        }

        this.channel?.removeClip?.(this);
        channel.addClip(this);
        this.channel = channel;

        this.updateElementStyle();

        return true;
    }


    // VISIBILITY

    public onVisibilityChanged = (visible: boolean) => {
        //
    }


    // EFFECTS

    public addEffect = (effect: ClipEffect) => {
        if (!effect){
            return false
        }
        if (! this.visualClip)
            return false;

        if (effect.stackLimit !== 0 && this.getEffectsByType(effect.type).length >= effect.stackLimit)
            return false;

        this.effects.push(effect);

        effect.clip = this;
        effect.init();

        let player = getVideoPlayer();
        if (player) {
            player.subtitleManager.createClipElements([ this ]);
            this.initEffects();
            this.updateEffects();
        }

        return true;
    }

    public removeEffect = (effect: ClipEffect) => {
        this.effects = this.effects.filter(v => v !== effect);

        let player = getVideoPlayer();
        if (player) {
            player.subtitleManager.createClipElements([ this ]);
            this.initEffects();
            this.updateEffects();
        }

        return true;
    }

    public hasEffect = (effectType?: string) => {
        if (effectType) {
            return !! this.effects.find(v => v.type === effectType);
        }

        return this.effects.length > 0;
    }

    public getEffect = <T = ClipEffect>(effectType: string): T => {
        let effect = this.effects.find(v => v.type === effectType);

        if (! effect)
            return null;

        return effect as T;
    }

    public getEffectsByType = (effectType: String) => {
        return this.effects.filter(effect => effect.type == effectType);
    }

    public sortEffectsByPriority = (effects: ClipEffect[]) => {
        return _.sortBy(effects, effect => effect.priority);
    }

    public initEffects = () => {
        this.sortEffectsByPriority(this.effects)
            .forEach(effect => {
                effect.create();
            });
    }

    public playEffects = () => {
        this.effects.forEach(effect => effect.play());
    }

    public pauseEffects = () => {
        this.effects.forEach(effect => effect.pause());
    }

    public updateEffects = () => {
        this.effects.forEach(effect => effect.update());
    }

    public updateEffectsStatus = () => {
        let [ subtitleManager ] = getSubtitleManager();
        let isVideoPaused       = subtitleManager.isVideoPaused();
        let currentTime         = subtitleManager.getVideoCurrentTime();

        if (isVideoPaused)
            return;

        this.effects.forEach(effect => {
            if (! effect.timeline || effect.timeline.completed)
                return;

            let isPlaying      = effect.isPlaying();
            let effectDuration = effect.timeline.duration;

            let newState = effect.animPlayType === AnimPlayType.Start
                ? this.startTime <= currentTime && this.startTime + effectDuration >= currentTime
                : this.startTime + this.duration.value - effectDuration <= currentTime && this.startTime + this.duration.value >= currentTime;

            if (newState !== isPlaying) {
                if (newState) {
                    effect.play();
                }
            }
        });
    }

    public getPropertyEditorComponents = () => {
        let components: any = [];

        this.editableProperties.forEach(name => {
            let property = this[name as keyof typeof this] as any;

            if (! property.isHidden()) {
                components.push( property.getPropertyEditorComponent() );
            }
        });

        return components;
    }

    public getEffectPropertyEditorComponents = () => {
        let components: any = [];

        this.effects.forEach(effect => {
                components.push( effect.getPropertyEditorComponents() );
        });

        return components;
    }


    // SUBTITLE

    public getSubtitleHTML = () => {
        return `<span>n/a</span>`;
    }

    public createSubtitleElement = (parent: JQuery<HTMLElement>) => {
        let html = this.getSubtitleHTML();

        let wrapper = $(`
            <div>
                <div class="subtitle-wrapper">
                    <div class="subtitle-content"></div>
                </div>
            </div>
        `)
        .addClass(["subtitle", `subtitle-${this.type}`])
        .attr("clip-id", this.id)
        .toggleClass("visible", this.visible);

        if (parent && parent.length > 0) {
            wrapper.appendTo(parent);
        }

        wrapper.find(".subtitle-content")
            .html(html);

        this.setSubtitleElementStyle(wrapper);

        this.effects.forEach(effect => {
            effect.create();
        });

        return wrapper;
    }

    public findSubtitleElement = () => {
        let [ subtitleManager ] = getSubtitleManager();

        return subtitleManager.subtitlesHolderElement.find(`.subtitle[clip-id="${this.id}"]`);
    }

    public updateElementStyle = () => {
        let element = this.findSubtitleElement();

        return this.setSubtitleElementStyle(element);
    }

    public setSubtitleElementStyle(element: JQuery<HTMLElement>) {
        let styles: ClipStyles[] = [
            {
                parent: {
                    "z-index": 1000 - this.channel.index()
                }
            }
        ];
        
        this.effects.forEach(effect => {
            let effect_styles = effect.getStyles();
    
            if (effect_styles) {
                styles.push(effect_styles);
            }
        });

        let merged_styles = mergeStyles(styles);

        if (merged_styles.parent) {
            element.css(merged_styles.parent);
        }
        if (merged_styles.wrapper) {
            element.find(".subtitle-wrapper").css(merged_styles.wrapper);
        }
        if (merged_styles.content) {
            element.find(".subtitle-content").css(merged_styles.content);
        }
    }
}


export const getClipEffectClassFromType = (type: string) => {
    switch (type) {
        case "BackgroundEffect":  return BackgroundEffect;
        case "FadeInEffect":      return FadeInEffect;
        case "FadeOutEffect":     return FadeOutEffect;
        case "TitleEffect":       return TitleEffect;
        case "TargetLineEffect":  return TargetLineEffect;
        case "ListSlideUpEffect": return ListSlideUpEffect;
        case "CounterEffect":     return CounterEffect;
        case "LatexEffect":       return LatexEffect;
        case "UppercaseEffect":   return UppercaseEffect;
        case "ShadowEffect":      return ShadowEffect;
        case "BorderEffect":      return BorderEffect;
    }
}

export const createClipEffectFromType = (type: string) => {
    let effectClass = getClipEffectClassFromType(type);

    if (! effectClass)
        return null;

    return new effectClass();
};

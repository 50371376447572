import React from "react";
import Tippy, { TippyProps } from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

interface Props extends TippyProps {
    children: React.ReactElement;
    text?: string;
    img?: string;
}

const Tooltip = ({ children, text, img, ...props }: Props) => {
    let content = null;

    if (text) {
        content = <div dangerouslySetInnerHTML={{ __html: text }} className="text-center" />;

    } else if (img) {
        content = <img src={img} style={{ maxWidth: "160px" }} />
    }

    if (! content)
        return children;

    return (
        <Tippy {...props} content={content} delay={500}>
            { children }
        </Tippy>
    );
}

export default Tooltip;

import { Command } from "../CommandService";

import { getVideoPlayer } from "@providers/videoplayer";
import Clip from "@models/clip/clip";
import Channel from "@models/channel";
import SubtitleEditorService from "../SubtitleEditorService";
import OnChangeDecorator from "@decorators/onchange";

interface IValues {
    duration: number,
    startTime: number,
    channel: Channel
}

export default class TimelineClipChangeCommand extends Command {
    public declare target: Clip;
    public values: IValues;

    constructor(target: any, values: IValues) {
        super(target);
        this.values = values;
    }

    protected apply(values: IValues) {
        if (typeof values.duration === "number") {
            this.target.setDuration(values.duration);
            OnChangeDecorator.callOnProperty(this.target, "duration");
        }
        if (typeof values.startTime === "number") {
            this.target.setStartTime(values.startTime)
            OnChangeDecorator.callOnProperty(this.target, "startTime");
        }
        if (values.channel) {
            this.target.setChannel(values.channel);
            OnChangeDecorator.callOnProperty(this.target, "channel");
        }

        window.projectPageForceRerender();

        let player = getVideoPlayer();
        player.subtitleManager.updateSubtitles();
        player.subtitleManager.updateEffects();

        SubtitleEditorService.setTarget(null);
    }
}

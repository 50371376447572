interface IKeyPressing {
    [key: string]: boolean;
}

let keyPressing: IKeyPressing = {};


document.addEventListener("keydown", e => {
    keyPressing[e.code] = true;
});

document.addEventListener("keyup", e => {
    delete keyPressing[e.code];
});

document.addEventListener("mousedown", e => {
    keyPressing["Mouse"+e.which] = true;
});

document.addEventListener("mouseup", e => {
    delete keyPressing["Mouse"+e.which];
});


const isPressing = (code: string) => {
    return keyPressing[code] || false;
}


export default { isPressing };

import React, { forwardRef, useEffect, useImperativeHandle, useRef} from "react";

export interface RulerProperties extends React.HTMLAttributes<HTMLCanvasElement> {
    segmentWidth: number;
    markings: number;
    unit?: string;
    offset?: number;
    numberOfTicks: number;
    fontColor?: TickColor;
    height: number;
    width: number;
    highlight?: { from: number, to: number };
    tickDesigns: { mainTick: TickDesign, evenTick: TickDesign, oddTick: TickDesign };
    labelFn?: (idx: number) => string;
    textAlign?: "left" | "right" | "center";
    direction?: "left" | "right";
    orientation?: "vertical" | "horizontal";
    onlySegments?: { from: number, to: number };
}

export type TickDesign = {
    length: number;
    width: number;
    color: TickColor;
}

export type TickColor = {
    activeColor: string;
    inactiveColor: string;
}

const Ruler = forwardRef((
    {
        onlySegments,
        fontColor,
        orientation,
        segmentWidth,
        markings,
        unit,
        offset = 0,
        height,
        width,
        style,
        highlight,
        numberOfTicks,
        tickDesigns,
        labelFn,
        textAlign,
        direction
    }: RulerProperties,
    ref: any
) => {
    const rulerRef = useRef<HTMLCanvasElement>();
    let font = "12px Roboto";

    const drawRuler = () => {
        const canvas = rulerRef.current;
        const context = canvas.getContext("2d");
        context.clearRect(0,0,Number.MAX_SAFE_INTEGER,Number.MAX_SAFE_INTEGER);

        if (orientation === "horizontal"){
            const drawSegment = (idx: number) => {
                // LABEL TEXT
                let value = markings * idx * ((direction === "left") ? -1 : 1);
                let labelText = "";
                if (labelFn){
                    labelText = labelFn(value);
                } else {
                    labelText = ` ${value}`;

                    if (unit){
                        labelText += unit;
                    }
                }

                // LABEL STYLE
                context.save();
                context.translate(0, 0);
                context.font = font;
                
                if (!highlight || (value >= highlight.from && value <= highlight.to)){
                    context.fillStyle = fontColor ? fontColor.activeColor : "#bbbbbb";
                } else {
                    context.fillStyle = fontColor ? fontColor.inactiveColor : "#898989";
                }
                context.textAlign = textAlign || "left";


                let labelPosition = idx * segmentWidth + offset;

                context.fillText(labelText, labelPosition, 10);
                context.restore();
                
                for (let i = 0; i <= numberOfTicks; i++) {
                    // TICK DESIGN
                    let currentTick : TickDesign = null;
                    if (i === 0 || i === numberOfTicks){
                        currentTick = tickDesigns.mainTick;
                    } else if (i % 2){
                        currentTick = tickDesigns.evenTick;
                    } else {
                        currentTick = tickDesigns.oddTick;
                    }

                    // TICK STYLE
                    //Melyik ticknek mi az értéke, pl 10, 20, 30. Azért kell, hogy csak a highlight értékig legyenek highlightedek pl.: ha 1920-ig megy, a tick értéke meg 1930, akkor inactive color
                    let lineheight = height * currentTick.length;
                    let segmentValue = markings * idx * ((direction === "left") ? -1 : 1);
                    let tickValue = (markings / numberOfTicks) * i + segmentValue;
                    if (!highlight || tickValue >= highlight.from && tickValue <= highlight.to){
                        context.fillStyle = currentTick.color.activeColor;
                    } else {
                        context.fillStyle = currentTick.color.inactiveColor;
                    }

                    let segmentPositionLeft = (idx * segmentWidth + offset)
                    let tickPositionLeft = (segmentWidth / numberOfTicks) * i + segmentPositionLeft; 
                    let tickPositionTop = height - lineheight;
                    context.fillRect(tickPositionLeft, tickPositionTop, currentTick.width, lineheight);
                }
            }

            let from = Math.ceil(offset / segmentWidth);
            let to = Math.ceil(rulerRef.current.getBoundingClientRect().width / segmentWidth) - from;
    
            for (let i = -from -1; i <= to +1; i++) {
                if (onlySegments){
                    if (onlySegments.from != null && onlySegments.to != null){
                        if (i >= onlySegments.from && i <= onlySegments.to){
                            drawSegment(i);
                        }

                    } else if (onlySegments.from != null){
                        if (i >= onlySegments.from){
                            drawSegment(i);
                        }

                    } else if (onlySegments.to != null){
                        if (i <= onlySegments.to){
                            drawSegment(i);
                        }
                    }

                } else {
                    drawSegment(i);
                }
            }

        } else if (orientation === "vertical"){
            // Külön kell bontani a verticalt, mert a szöveg elforgatásáhot az egész contextet forgatni kell.
            const drawSegment = (idx: number) => {
                // LABEL TEXT
                let value = markings * idx * ((direction === "left") ? -1 : 1);
                let labelText = "";
                if (labelFn){
                    labelText = labelFn(value);
                } else {
                    labelText = ` ${value}`;

                    if (unit){
                        labelText += unit;
                    }
                }

                // LABEL STYLE
                context.save();
                context.translate(0, 0);
                context.rotate(-Math.PI/2)
                context.font = font;
                if (!highlight || (value >= highlight.from && value <= highlight.to)){
                    context.fillStyle = fontColor ? fontColor.activeColor : "#bbbbbb";
                } else {
                    context.fillStyle = fontColor ? fontColor.inactiveColor : "#898989";
                }
                context.textAlign = textAlign || "left";


                context.fillText(labelText, -(idx * segmentWidth + offset), 10);
                context.restore();
                
                for (let i = 0; i <= numberOfTicks; i++) {
                    // TICK DESIGN
                    let currentTick : TickDesign = null;
                    if (i === 0 || i === numberOfTicks){
                        currentTick = tickDesigns.mainTick;
                    } else if (i % 2){
                        currentTick = tickDesigns.evenTick;
                    } else {
                        currentTick = tickDesigns.oddTick;
                    }
                    
                    // TICK STYLE
                    // Melyik ticknek mi az értéke, pl 10, 20, 30. Azért kell, hogy csak a highlight értékig legyenek highlightedek pl.: ha 1920-ig megy, a tick értéke meg 1930, akkor inactive color
                    let linewidth = width * currentTick.length;
                    let segmentValue = markings * idx * ((direction === "left") ? -1 : 1);
                    let tickValue = (markings / numberOfTicks) * i + segmentValue;
                    if (!highlight || tickValue >= highlight.from && tickValue <= highlight.to){
                        context.fillStyle = currentTick.color.activeColor;
                    } else {
                        context.fillStyle = currentTick.color.inactiveColor;
                    }
                    
                    let segmentPositionLeft = (idx * segmentWidth + offset)
                    let tickPositionTop = (segmentWidth / numberOfTicks) * i + segmentPositionLeft; 
                    let tickPositionLeft = width - linewidth;

                    context.fillRect(tickPositionLeft, tickPositionTop, linewidth,  currentTick.width);
                }
            }

            let from = Math.ceil(offset / segmentWidth);
            let to = Math.ceil(rulerRef.current.getBoundingClientRect().height / segmentWidth) - from;
    
            for (let i = -from -1; i <= to + 1; i++) {
                if (onlySegments){
                    if (onlySegments.from != null && onlySegments.to != null){
                        if (i >= onlySegments.from && i <= onlySegments.to){
                            drawSegment(i);
                        }

                    } else if (onlySegments.from != null){
                        if (i >= onlySegments.from){
                            drawSegment(i);
                        }
                        
                    } else if (onlySegments.to != null){
                        if (i <= onlySegments.to){
                            drawSegment(i);
                        }
                    }

                } else {
                    drawSegment(i);
                }
            }
        }
    }


    useEffect(() => {
        drawRuler();
    }, [])

    const changeProperty = (type: string, value: any) => {
        switch (type) {
            case "width":
                width = value;
                rulerRef.current.width = value;
                break;
            case "height":
                height = value;
                rulerRef.current.height = value;
                break;
            case "segmentWidth":
                segmentWidth = value;
                break;
            case "markings":
                markings = value;
                break;
            case "offset":
                offset = value;
                break;
            case "style":
                style = {...style, ...value};
                break;
            case "onlySegments":
                onlySegments = value;
                break;
            case "font":
                font = value;
            default:
                break;
        }

        drawRuler();
    } 

    useImperativeHandle(ref, () => {
        return {
            changeProperty,
            drawRuler
        }
    })

    return (
        <canvas
            id="ruler"
            style={{
                ...style
            }}
            width={width}
            height={height}
            ref={rulerRef}
        ></canvas>
    )
})

export default Ruler;
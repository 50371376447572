import React from "react";

import { OnChange } from "@decorators/onchange";
import { Set } from "@decorators/set";
import ClipEffect from "@models/clipeffect";
import Color from "@clip/properties/color";
import Position from "@clip/properties/position";
import Range from "@clip/properties/range";
import Select, { SelectOption } from "@clip/properties/select";
import { ClipStyles } from "@models/clip/clip";

const shadowTypes : SelectOption[] = [
    {
        value: "box",
        i18nLabel: "common:shadow_type.box"
    },
    {
        value: "text",
        i18nLabel: "common:shadow_type.text"
    }
];

export default class ShadowEffect extends ClipEffect {
    public type = "ShadowEffect";
    public stackLimit: number = 0;

    @Set({ i18nKey: "pages.project.property_name.shadow_type", options: shadowTypes })
    @OnChange((effect: ShadowEffect) => {
        effect.clip.updateElementStyle();
        window.projectPageForceRerender();
    })
    public shadowType: Select = new Select(this);

    @Set({ i18nKey: "pages.project.property_name.color", value: "#000000" })
    @OnChange((effect: ShadowEffect) => {
        effect.clip.updateElementStyle();
    })
    public color: Color = new Color(this);

    @Set({ i18nKey: "pages.project.property_name.offset" })
    @OnChange((effect: ShadowEffect) => {
        effect.clip.updateElementStyle();
    })
    public offset: Position = new Position(this);

    @Set({ i18nKey: "pages.project.property_name.blur_fadeout", value: 5 , min: 0, max: 50})
    @OnChange((effect: ShadowEffect) => {
        effect.clip.updateElementStyle();
    })
    public blurFadeout: Range = new Range(this);

    @Set({ i18nKey: "pages.project.property_name.blur_start", value: 5 , min: 0, max: 50})
    @OnChange((effect: ShadowEffect) => {
        effect.clip.updateElementStyle();
    })
    public blurStart: Range = new Range(this);

    constructor(){
        super();

        this.shadowType.value = "box";
        this.color.value = "#000000";
        this.offset.x = 0;
        this.offset.y = 0;
    }

    public exportPayload() {
        let payload = super.exportPayload()

        payload.data.shadowType   = this.shadowType.exportPayload();
        payload.data.color        = this.color.exportPayload();
        payload.data.offset       = this.offset.exportPayload();
        payload.data.blurFadeout  = this.blurFadeout.exportPayload();
        payload.data.blurStart    = this.blurStart.exportPayload();

        return payload;
    }

    public getPropertyEditorComponents() {
        return (
            <this.PropertyEditorWrapperComponent property={this}>
                { this.shadowType.getPropertyEditorComponent() }
                { this.color.getPropertyEditorComponent() }
                { this.offset.getPropertyEditorComponent() }
                { this.blurFadeout.getPropertyEditorComponent() }
                { this.shadowType.value === "box" && this.blurStart.getPropertyEditorComponent() }
            </this.PropertyEditorWrapperComponent>
        );
    }

    public getStyles(): ClipStyles {
        return {
            content: this.shadowType.value === "box"
                ? {
                    boxShadow:  `${this.offset.x}px ${this.offset.y}px ${this.blurFadeout.value}px ${this.blurStart.value}px ${this.color.value}`,
                    textShadow: ""
                }
                : {
                    textShadow: `${this.offset.x}px ${this.offset.y}px ${this.blurFadeout.value}px ${this.color.value}`,
                    boxShadow:  ""
                }
        };
    }
}

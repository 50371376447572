import React from "react";
import $ from "jquery";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFont, faImage, faMapPin, faVectorSquare } from "@fortawesome/free-solid-svg-icons";

import config from "@config";
import { apiRequest } from "@utils/api";
import Project from "@models/project";
import VideoPlayer from "@models/videoplayer";

import Tooltip from "@components/tippy/tooltip";
import Channel from "@models/channel";

type Props = {
    project: Project;
    player: VideoPlayer;
    disabled?: boolean;
}


const AreaToolbar = ({ project, player, disabled }: Props) => {
    const { t } = useTranslation([ "mve", "common" ]);

    const createTextClip = () => {
        if (! project.selectedChannel)
            return false;

        let clip = project.selectedChannel.createTextClip();
        clip.setStartTime(player.currentTime());

        window.projectPageForceRerender();
    }

    const createImageClip = async () => {
        if (! project.selectedChannel)
            return false;

        let input = $<HTMLInputElement>(`<input type="file" accept="${config.CLIP_IMAGE_ACCEPTED_EXTENTIONS.join(", ")}" style="display: none">`)
            .appendTo("body")
            .trigger("click");
            
        input.on("input", e => {
            let file = input[0].files[0];
            
            if (file) {
                const data = new FormData();
                data.append("type", "asset");
                data.append("file", file);

                apiRequest("POST", `/project/${project.id}/media/upload`, data, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
                .then(res => {
                    if (res.success) {
                        let { name, width, height } = res.result;
                        
                        let imageRatio = (width / height) / player.getVideoScaleRatio();
                        let scaleX     = 30;
                        let scaleY     = scaleX / imageRatio;
                        
                        let clip = project.selectedChannel.createImageClip(name);
                        clip.setStartTime(player.currentTime());
                        
                        clip.position.set(
                            50 - clip.scale.x / 2,
                            50 - clip.scale.y / 2
                        );

                        clip.scale.set(scaleX, scaleY, imageRatio);
                
                        window.projectPageForceRerender();
                    }
                });
            }
        });

        $(window).one("focus", e => {
            setTimeout(() => {
                input.remove();
            }, 100);
        });
    }

    const createShapeClip = () => {
        if (! project.selectedChannel)
            return false;

        let clip = project.selectedChannel.createShapeClip();
        clip.setStartTime(player.currentTime());

        window.projectPageForceRerender();
    }

    const createActionClip = () => {
        if (! project.selectedChannel)
            return false;

        let clip = project.selectedChannel.createActionClip();
        clip.setStartTime(player.currentTime());

        window.projectPageForceRerender();
    }

    return <>
        <div className="area-toolbar-placeholder"></div>
        
        <div className={classNames("area-toolbar d-flex flex-column gap-3", { "area-disabled": disabled })}>
            <Tooltip text={ t("pages.project.actions.new_text") }>
                <FontAwesomeIcon
                    icon={faFont}
                    className="toolbar-btn"
                    onClick={createTextClip}
                />
            </Tooltip>

            <Tooltip text={ t("pages.project.actions.new_image") }>
                <FontAwesomeIcon
                    icon={faImage}
                    className="toolbar-btn"
                    onClick={createImageClip}
                />
            </Tooltip>

            <Tooltip text={ t("pages.project.actions.new_image") }>
                <FontAwesomeIcon
                    icon={faVectorSquare}
                    className="toolbar-btn"
                    onClick={createShapeClip}
                />
            </Tooltip>

            <Tooltip text={ t("pages.project.actions.new_marker") }>
                <FontAwesomeIcon
                    icon={faMapPin}
                    className="toolbar-btn"
                    onClick={createActionClip}
                />
            </Tooltip>
        </div>
    </>;
}

export default AreaToolbar;

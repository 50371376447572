import _ from "lodash";
import { parse as transformParse, stringify as transformStringify } from "transform-parser";
import { ClipStyles } from "@models/clip/clip";
import { camelToKebabCase } from "./string";

export type CSSProperties = Record<string, string | number>;

const stackableCSSProperties = {
    "text-shadow": "list",
    "box-shadow":  "list",
    "transform":   "text"
};

const cssPropertyStackHandlers = {
    "list": (currentValue, newValue) => {
        return currentValue + ", " + newValue;
    },
    
    "text": (currentValue, newValue) => {
        return currentValue + " " + newValue;
    }
};

export const mergeStyles = (list: ClipStyles[]): ClipStyles => {
    let merged: ClipStyles = {};

    _.forEach(list, v => {
        _.forEach(v, (styles, group) => {
            if (! merged[group]) {
                merged[group] = {};
            }

            _.forEach(styles, (prop_value, prop_name) => {
                prop_name = camelToKebabCase(prop_name);
                
                if (prop_value === undefined || prop_value === null) {
                    prop_value = "";
                
                } else if (typeof prop_value !== "string") {
                    prop_value = String(prop_value);
                }

                const currentValue = merged[group][prop_name];
            
                if (currentValue !== undefined && stackableCSSProperties[prop_name]) {
                    if (currentValue === "") {
                        merged[group][prop_name] = prop_value;

                    } else if (prop_value !== "") {
                        const handler = cssPropertyStackHandlers[stackableCSSProperties[prop_name]];
                        
                        merged[group][prop_name] = handler(merged[group][prop_name], prop_value);
                    }

                } else {
                    merged[group][prop_name] = prop_value;
                }
            });
        });
    });

    return merged;
}

export const objToCss = (obj: any) => {
    let style: any = [];

    for (let k in obj) {
        let properyName = k.split(/(?=[A-Z])/).map(s => s.toLowerCase()).join("-");

        style.push(`${properyName}:${obj[k]}`);
    }

    return style.join(";");
}

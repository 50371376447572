import React, { useState } from "react";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ClipPropertyChangeCommand from "@services/commands/clip_property_change";
import SubtitleEditorService from "@services/SubtitleEditorService";
import ClipProperty from "@models/clipproperty";

import NumberInput from "@components/inputs/number";
import { CSSProperties } from "@utils/styles";


export default class Scale extends ClipProperty {
    public i18nKey: string = "pages.project.property_name.scale";

    public x: number = 0;
    public y: number = 0;
    public ratio: number = null;


    public exportPayload() {
        return {
            ...super.exportPayload(),
            x:     this.x,
            y:     this.y,
            ratio: this.ratio
        };
    }


    public set(x?: number, y?: number, ratio?: number) {
        let values: any = {};

        if (ratio !== undefined) {
            values.ratio = ratio ? Number(ratio.toFixed(2)) : null;

            SubtitleEditorService.setKeepRatio(!! values.ratio);
        }

        if (x) values.x = Number(x.toFixed(2));
        if (y) values.y = Number(y.toFixed(2));

        let cmd = new ClipPropertyChangeCommand(this.parent, this, values);
        cmd.setGrouping(true);
        cmd.execute();
    }

    public toCSS(): CSSProperties {
        return {
            width:  this.x + "%",
            height: this.y + "%"
        };
    }

    public getPropertyEditorComponent() {
        return (
            <this.PropertyEditorWrapperComponent property={this}>
                <this.PropertyEditor />
            </this.PropertyEditorWrapperComponent>
        );
    }

    protected PropertyEditor = () => {
        const [x, setX] = useState(this.x);
        const [y, setY] = useState(this.y);
        const [ratio, setRatio] = useState(this.ratio);

        const onInputX = (e: any) => {
            let newX = e.currentTarget.valueAsNumber;
            let newY = ratio ? (newX / ratio) : y;

            this.set(newX, newY);
            setX(this.x);
            setY(this.y);
        }

        const onInputY = (e: any) => {
            let newY = e.currentTarget.valueAsNumber;
            let newX = ratio ? (newY * ratio) : x;

            this.set(newX, newY);
            setX(this.x);
            setY(this.y);
        }

        const onToggleRatio = () => {
            let newRatio = ratio ? null : x / y;

            this.set(null, null, newRatio);
            setRatio(newRatio);
        }

        return (
            <div className="d-flex">
                <div style={{ flex: 1 }}>
                    <div className="d-flex gap-2">
                        <span>x</span>
                        <NumberInput
                            value={x}
                            min={0}
                            onInput={onInputX}
                        />
                    </div>

                    <div className="d-flex mt-1 mb-2 gap-2">
                        <span>y</span>
                        <NumberInput
                            value={y}
                            min={0}
                            onInput={onInputY}
                        />
                    </div>
                </div>

                <div className="d-flex align-items-center">
                    <FontAwesomeIcon
                        icon={faLink}
                        className="icon-btn p-2"
                        style={{
                            opacity: ratio ? 1 : 0.5
                        }}
                        onClick={onToggleRatio}
                    />
                </div>
            </div>
        );
    }
}

import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ControlledMenuProps } from "@szhsin/react-menu";
import { faMapPin, faPaste, faTrash } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";

import ClipboardService from "@services/ClipboardService";
import ClipCopyHandler from "@services/clipboard_handlers/clip_copy_handler";
import { useVideoPlayer } from "@providers/videoplayer";
import { ControlledMenu } from "@components/menu/menu";

import ClipModel from "@models/clip/clip";
import ChannelModel, { ChannelType } from "@models/channel";
import Clip from "./clip";

type ChannelProps = {
    channel: ChannelModel;
    channel_index: number;
    className?: string;
}


const Channel = ({ channel, channel_index, className, ...props }: ChannelProps) => {
    const { t } = useTranslation([ "mve", "common" ]);
    const [menuOpened, setMenuOpened] = useState<ControlledMenuProps["anchorPoint"]>(null);
    const [player] = useVideoPlayer();
    const clipboardHandler = ClipboardService.get();

    const channelRef = useRef<HTMLDivElement>();

    const deleteChannel = () => {
        channel.delete();
        window.projectPageForceRerender();
    }
    
    const pasteClip = () => {
        if (clipboardHandler instanceof ClipCopyHandler) {
            clipboardHandler.paste(channel.project.selectedChannel, player.currentTime());
        }
    }

    let menuData = [
        { title: t("pages.project.channel").toUpperCase(), header: true },
        { title: t("common:actions.delete"), icon: faTrash, onClick: deleteChannel }
    ];

    const createTimelineMarker = () => {
        const clickedPC = (menuOpened.x - channelRef.current.getBoundingClientRect().left) / channelRef.current.getBoundingClientRect().width;

    
        let clip = channel.createTimelineMarkerClip();
        clip.setStartTime(player.duration() * clickedPC);

        window.projectPageForceRerender();
        

    }

    let markerMenuData = [
        { title: t("Create marker"), icon: faMapPin, onClick: createTimelineMarker}
    ]

    if (clipboardHandler instanceof ClipCopyHandler) {
        menuData.push({ title: t("pages.project.actions.paste_clip"), icon: faPaste, onClick: pasteClip });
    }

    return <>
        <div
            {...props}
            ref={channelRef}
            channel-index={channel_index}
            className={classNames("channel", className, { "selected": channel.selected, "locked": channel.locked, "hidden": channel.hidden, "marker-channel": channel.type === ChannelType.MARKER })}
            onContextMenu={e => {
                e.stopPropagation();
                e.preventDefault();
                setMenuOpened({ x: e.clientX, y: e.clientY })
            }}
            onMouseDown={e => {
                if (channel.type === ChannelType.NORMAL){
                    channel.project.setSelectedChannel(channel);
                }
                if (e.currentTarget === e.target){
                    channel.project.setSelectedClip(null);
                }
                window.projectPageForceRerender();
            }}
        >
            {channel.clips.map((clip: ClipModel, i: number) => (
                <Clip
                    key={i}
                    channel={channel}
                    clip={clip}
                />
            ))}
        </div>

        <ControlledMenu
            anchorPoint={menuOpened}
            opened={!!menuOpened}
            onClose={_ => setMenuOpened(null)}
            data={(channel.type === ChannelType.NORMAL) ? menuData : markerMenuData}
        />
    </>;
};

export default Channel;

import _ from "lodash";
import Color from "color";
import Pickr from "@simonwep/pickr";
import "@simonwep/pickr/dist/themes/nano.min.css";

export type ColorValue = {
    hex: string;
    hsl: string;
    rgb: string;
}

export const createColorPicker = (el: string | HTMLElement, onChange: Function, defaultValue: string, extraOptions?: Pickr.Options) => {
    const pickr = Pickr.create(
        _.merge(
            {
                ...extraOptions,
                el: el,
                theme: "nano",
                comparison: false,
                default: defaultValue,
                swatches: extraOptions.swatches,
            
                components: {
                    palette: true,
                    preview: true,
                    opacity: true,
                    hue: true,
            
                    interaction: {
                        input: true,
                        cancel: true,
                        clear: false,
                        save: false
                    }
                }
            },
            extraOptions
        )
    )
    .on("change", (color: any) => {
        onChange( color.toHEXA().toString() );
    })
    .on("cancel", (inst) => {
        onChange( inst.getColor().toHEXA().toString() );
    })
    .on("clear", () => {
        onChange(null);
    });

    return pickr;
}

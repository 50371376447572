
import Color from "color";
import { forEach } from "lodash";

import { Set } from "@decorators/set";
import OnChangeDecorator, { OnChange } from "@decorators/onchange";
import Switch from "@clip/properties/switch";
import Text from "@clip/properties/text";

import { ClipBackendPayload } from "@models/project";
import Channel, { ChannelType } from "@models/channel";
import Clip, { ClipType } from "@models/clip/clip";
import ColorProperty from "@clip/properties/color";

const swatches = [
    "#FF3733",
    "#FF9233",
    "#ffe200",
    "#FFE833",
    "#30ff00",
    "#86DB57",
    "#00c2ff",
    "#33CEFF",
    "#7C33FF",
    "#D133FF",
    "#FF33CD",
    "#FF3391"
]

export default class MarkerClip extends Clip{
    public type: ClipType = "MarkerClip";
    public visualClip: boolean = false;

    @Set({ i18nKey: "Point" })
    @OnChange((clip: MarkerClip) => {
        clip.updateVisuals();
        clip.duration.set(clip.isPointMarker.value ? 0 : 10000);
        clip.isDurationEditable = !clip.isPointMarker.value;
        
        if (!clip.isDurationEditable){
            clip.editableProperties = clip.editableProperties.filter(prop => prop != "duration");

        } else {
            if (!clip.editableProperties.includes("duration")){
                clip.editableProperties.unshift("duration");
            }
        }

        window.projectPageForceRerender();
    })
    public isPointMarker: Switch = new Switch(this);

    @Set({ i18nKey: "pages.project.property_name.description", simple: true })
    @OnChange((clip: MarkerClip) => {
        clip.updateVisuals();
    })
    public description: Text = new Text(this);

    @Set({ i18nKey: "pages.project.property_name.title", simple: true })
    @OnChange((clip: MarkerClip) => {
        clip.updateVisuals();
    })
    public title: Text = new Text(this);

    @Set({ i18nKey: "Color", swatches: swatches, value: swatches[0], adjustOpacity: false, onlySwatches: true})
    public color: ColorProperty = new ColorProperty(this);


    constructor(channel: Channel) {
        super(channel);
        
        this.editableProperties.push("isPointMarker", "title","description", "color");

        OnChangeDecorator.subscribe(this, [ "color" ], () => {
            this.updateVisuals();
        });
    }

    public exportPayload() {
        let payload = super.exportPayload();
        payload.data.isTimeInterval = this.isPointMarker.exportPayload();
        payload.data.description    = this.description.exportPayload();
        payload.data.title          = this.title.exportPayload();
        payload.data.color          = this.color.exportPayload();

        return payload;
    }

    public applyPayload(payload: ClipBackendPayload) {
        super.applyPayload(payload);

        if (payload.type === "MarkerClip") {
            this.isPointMarker.applyPayload(payload.data.isTimeInterval);
            this.description.applyPayload(payload.data.description);
            this.title.applyPayload(payload.data.title);
            this.color.applyPayload(payload.data.color);

            if (this.channel.type === ChannelType.NORMAL){
                this.isDurationEditable = this.isPointMarker.value;
            }
        }
    }

    public updateVisuals(){
        let clipElement = $(".clip").filter((_, element) => {return parseInt(element.attributes.getNamedItem("data-id").value) == this.id})[0]
        
        let preview = clipElement.getElementsByClassName("preview")[0];
        let border = clipElement.getElementsByClassName("marker-clip-border")[0];
        let markerCaret = clipElement.getElementsByClassName("point-marker")[0];
        let resizeDots = clipElement.getElementsByClassName("resize-dots");
        
        if (preview){
            (preview as HTMLDivElement).style.backgroundColor = `
            ${Color(this.color.value).desaturate(0.6).darken(0.2).hexa()}
            `;
            (preview as HTMLDivElement).textContent = this.title.value;
        }
        if (border){
            (border as HTMLDivElement).style.borderColor = this.color.value;
        }
        if (markerCaret){
            (markerCaret as HTMLDivElement).style.backgroundColor = this.color.value;
            (markerCaret as HTMLDivElement).style.display = (this.isPointMarker.value) ? "block" : "none";
        }
        if (resizeDots.length > 0){
            forEach(resizeDots, (dot) => {
                (dot as SVGElement).style.backgroundColor = this.color.value;
                (dot as SVGElement).style.display = (this.isPointMarker.value) ? "none" : "block";
            })
        }
    }
}

import React from "react";
import _ from "lodash";
import anime, { AnimeTimelineInstance } from "animejs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faTrash } from "@fortawesome/free-solid-svg-icons";


import SubtitleEditorService from "@services/SubtitleEditorService";
import { getSubtitleManager } from "@providers/subtitlemanager";
import SetDecorator from "@decorators/set";

import AnimPlayType from "@enums/animplaytype";
import AnimPriorityGroup from "@enums/animprioritygroup";

import Clip, { ClipStyles } from "./clip/clip";
import { useTranslation } from "react-i18next";

export type AnimPriority = [AnimPriorityGroup, number];
type AnimElements = { [key: string]: JQuery<HTMLElement> };

export default class ClipEffect {
    public type: string;
    public animPlayType: AnimPlayType = AnimPlayType.Start;
    public priority: AnimPriority     = [AnimPriorityGroup.Normal, 0];
    public stackLimit: number         = 1;
    
    public clip: Clip                          = null;
    public timeline: AnimeTimelineInstance     = null;
    public isEditorComponentCollapsed: boolean = true;
    protected elements: AnimElements           = {};


    public exportPayload(): any {
        return {
            type: this.type,
            data: {}
        };
    }

    public applyPayload(payload: any) {
        _.each(payload.data, (v, k) => {
            this[k].applyPayload(v);
        });
    }

    
    public init() {
        SetDecorator.execute(this);
    }


    public PropertyEditorWrapperComponent({ property, children, title }: any) {
        const { t } = useTranslation([ "mve", "common" ]);

        return (
            <>
                <div className="title-row mb-1">
                    <div
                        style={{
                            display: "flex",
                            gap: "0.5em",
                            cursor: "pointer"
                        }}
                        onClick={_ => {
                            property.isEditorComponentCollapsed = !property.isEditorComponentCollapsed;
                            window.projectPageForceRerender();
                        }}
                    >
                        { children != undefined && <FontAwesomeIcon
                            className="icon-btn"
                            style={{
                                transition: "transform 0.3s",
                                transform: property.isEditorComponentCollapsed ? "rotate(-90deg)" : ""
                            }}
                            icon={faCaretDown}
                        />}
                        <span className="title fw-bold">{ t(`pages.project.effect_name.${property.type}`) }</span>
                    </div>

                    <FontAwesomeIcon
                        className="icon-btn"
                        icon={faTrash}
                        onClick={_ => {
                            property.remove();
                            window.projectPageForceRerender();
                            SubtitleEditorService.setTarget(null);
                        }}
                    />
                </div>

                <div className="effect-properties" style={{
                    height: "100%",
                    display: "grid",
                    transition: "grid-template-rows 0.3s ease-in-out",
                    gridTemplateRows: property.isEditorComponentCollapsed ? "0fr" : "1fr"
                    }}>
                    <div style={{overflow: "hidden"}}>
                            { children }
                    </div>
                </div>
            </>
        );
    }

    public getPropertyEditorComponents() {
        return <>n/a</>;
    }


    // ELEMENTS

    public create() {
        this.timeline?.pause?.();
        this.timeline = null;

        const subtitleElement = this.clip.findSubtitleElement();
        
        this.deleteElements();
        this.createElements(subtitleElement);

        this.createTimeline(subtitleElement);
    }

    public remove() {
        this.deleteElements();
        this.clip.removeEffect(this);
    }

    public createElements(subtitleElement: JQuery<HTMLElement>) {
        /* "abstract", functionality defined by children */
    }

    public deleteElements() {
        _.each(
            _.values(this.elements),
            (element) => element.remove()
        );
    }

    public getStyles(): ClipStyles | null {
        return null;
    }


    // ANIMATION

    public createTimeline(subtitleElement: JQuery<HTMLElement>) {
        this.timeline = anime.timeline({
            easing:   "easeInOutSine",
            autoplay: false
        });

        this.createAnimations(subtitleElement);
        this.update();
    }

    public createAnimations(subtitleElement: JQuery<HTMLElement>) {
        /* "abstract", functionality defined by children */
    }

    public getAnimationDuration() {
        return this?.timeline?.duration || 0;
    }


    // CONTROLS

    public update() {
        if (! this.timeline)
            return false;

        let [ subtitleManager ] = getSubtitleManager();
        let currentTime         = subtitleManager.getVideoCurrentTime();

        let elapsed  = this.animPlayType === AnimPlayType.Start
            ? currentTime - this.clip.startTime
            : Math.max(0, currentTime - (this.clip.startTime + this.clip.duration.value - this.timeline.duration));
            
        if (elapsed >= 0) {
            let progress = Math.min(1, elapsed / this.timeline.duration);

            this.timeline.pause();
            this.timeline.seek(this.timeline.duration * progress);
        }
    }

    public play() {
        if (! this.timeline)
            return false;

        this.update();
        this.timeline.play();
    }

    public pause() {
        if (! this.timeline)
            return false;

        this.timeline.pause();
    }

    public isPlaying() {
        return this.timeline && !this.timeline.paused;
    }
}

import React, { useState } from "react";
import { useUpdateEffect } from "usehooks-ts";

import ClipPropertyChangeCommand from "@services/commands/clip_property_change";
import ClipProperty from "@models/clipproperty";
import { useTranslation } from "react-i18next";

export type SelectOption = {
    value: string;
    i18nLabel?: string;
    label?: string;
};

export default class Select extends ClipProperty {
    public i18nKey: string = "pages.project.property_name.select";

    public value: string = "";

    public options: SelectOption[] = [
        {
            value: "",
            label: "-"
        }
    ];

    public exportPayload() {
        return {
            ...super.exportPayload(),
            value: this.value
        };
    }


    public set(value: string) {
        const cmd = new ClipPropertyChangeCommand(this.parent, this, { value })
        cmd.execute();
    }


    public getPropertyEditorComponent() {
        return (
            <this.PropertyEditorWrapperComponent property={this}>
                <this.PropertyEditor />
            </this.PropertyEditorWrapperComponent>
        );
    }

    protected PropertyEditor = () => {
        const [value, setValue] = useState(this.value);
        
        const { t } = useTranslation();

        useUpdateEffect(() => {
            this.set(value);
        }, [ value ]);

        return (
            <div>
                <select value={value} onChange={e => setValue(e.currentTarget.value)}>
                    {this.options.map(v => (
                        <option key={ v.value } value={ v.value }>
                            { v.i18nLabel ? t(v.i18nLabel) : (v.label || "n/a") }
                        </option>
                    ))}
                </select>
            </div>
        );
    }
}

import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useInterval } from "usehooks-ts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faReply, faShare, faUnlock } from "@fortawesome/free-solid-svg-icons";

import CommandService from "@services/CommandService";

import Project from "@models/project";
import ProjectMenu from "../projectmenu";
import { useTranslation } from "react-i18next";

type Props = {
    project: Project;
}


const AreaHeader = ({ project }: Props) => {
    const { t } = useTranslation([ "mve", "common" ]);
    const [lockRemainingMins, setLockRemainingMins] = useState(0);

    const toggleLock = async () => {
        await project.toggleLock();
        updateRemainingMinutes();
    }

    const updateRemainingMinutes = () => {
        if (! project.isLocked())
            return setLockRemainingMins(0);
            
        let remainingMiliSec = (project.lockInfo.expire_at * 1000) - Date.now();
        
        setLockRemainingMins(
            Math.max(0, Math.floor(remainingMiliSec / 60000))
        );
    }

    useInterval(() => {
        if (lockRemainingMins > 0) {
            setLockRemainingMins(prev => prev - 1);
        }
    }, 60000)
    
    useEffect(() => {
        updateRemainingMinutes();
    }, [])

    return (
        <div className="area-header d-flex justify-content-between">
            <div className="d-flex">
                <div className="app-menu-btn">
                    <ProjectMenu />
                </div>

                <span className="header-name">{ project.name } ({ project.azon })</span>
            </div>
            
            <div className="d-flex gap-4">
                <div className="d-flex gap-2">
                    <div
                        className={classNames("lock-btn", { "active": project.isLocked() })}
                        onClick={_ => toggleLock()}
                    >
                        <FontAwesomeIcon icon={project.isLocked() ? faLock : faUnlock} className="me-2" />
                        { project.isLocked() ? t("pages.project.x_min", { count: lockRemainingMins }) : t("common:actions.lock") }
                    </div>
                </div>

                <div className="d-flex gap-2">
                    <FontAwesomeIcon
                        className={classNames("icon-btn", { "disabled": !CommandService.canUndo() })}
                        icon={faReply}
                        onClick={_ => CommandService.undo()}
                    />

                    <FontAwesomeIcon
                        className={classNames("icon-btn", { "disabled": !CommandService.canRedo() })}
                        icon={faShare}
                        onClick={_ => CommandService.redo()}
                    />
                </div>
            </div>
        </div>
    );
}

export default AreaHeader;

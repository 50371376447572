import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup } from "react-bootstrap";
import classNames from "classnames";

import Project from "@models/project";
import MarkerClip from "@models/clip/markerclip";

type Props = {
    project: Project;
    disabled?: boolean,
}


const AreaProperties = ({ project, disabled }: Props) => {
    const { t } = useTranslation([ "mve" ]);
    const [areaPropertiesTab, setAreaPropertiesTab] = useState("properties")

    if (! project.selectedClip)
        return <div className="area-properties" />;

    return (
        <div className="area-properties">
            <ButtonGroup size="sm" className="mb-3">
                <Button variant="dark" active={areaPropertiesTab === "properties"} onClick={_ => setAreaPropertiesTab("properties")}>
                    { t("pages.project.properties") }
                </Button>
                { !( project.selectedClip instanceof MarkerClip ) && <Button variant="dark" active={areaPropertiesTab === "effects"} onClick={_ => setAreaPropertiesTab("effects")}>
                    { t("pages.project.effects") }
                </Button>}
            </ButtonGroup>

            <div className={classNames({ "area-disabled": disabled })}>
                {areaPropertiesTab === "properties" && <>
                    {project.selectedClip.getPropertyEditorComponents().map((component: any, i: number) =>
                        <div key={`clip${project.selectedClip.id}-${i}`} className="clip-property-wrapper pb-2">
                            { component }
                        </div>
                    )}
                </>}

                {areaPropertiesTab === "effects" && <>
                    {project.selectedClip.getEffectPropertyEditorComponents().map((component: any, i: number) =>
                        <div key={`clip${project.selectedClip.id}-effect-${i}`} className="clip-effect-wrapper pb-3 mb-2">
                            { component }
                        </div>
                    )}
                </>}
            </div>
        </div>
    );
}

export default AreaProperties;

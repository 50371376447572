import React, { useState } from "react";
import { Form } from "react-bootstrap";

import { resolveLanguageAssetUrls } from "@utils/language";
import Project from "@models/project";
import VideoPlayer from "@models/videoplayer";
import { useTranslation } from "react-i18next";

type Props = {
    children: any;
    project: Project;
    player: VideoPlayer;
}


const AreaPlaybackSettings = ({ project, player, children }: Props) => {
    const { t } = useTranslation([ "mve", "common" ]);
    const [language, setLanguage] = useState(project.getPrimaryLanguage());
    const [srt, setSRT] = useState("");

    const changeVideoSrc = async (path: string) => {
        await player.loadVideo(
            project.files.videos.find(v => v.path === path)
        );

        window.projectPageForceRerender();
    }

    const changeLanguage = async (code: string) => {
        if (srt) {
            await changeSrt("");
        }

        window.toggleLoadingOverlay(true);

        const isPlaying = ! player.video.paused();
        player.setPaused(true);

        if (! await project.loadTranslation(code)) {
            window.toggleLoadingOverlay(false);
            return;
        }

        setLanguage(code);

        let languageAssetUrls = resolveLanguageAssetUrls(project.languages, code);

        if (languageAssetUrls?.narration) {
            player.audioNarration.src = project.getServerStoragePath(languageAssetUrls.narration);
        }
        if (languageAssetUrls?.music) {
            player.audioMusic.src = project.getServerStoragePath(languageAssetUrls.music);
        }

        player.setCurrentTime( player.currentTime() );

        if (isPlaying) {
            player.setPaused(false);
        }
        window.toggleLoadingOverlay(false);
    }

    const changeSrt = async (translation_language: string) => {
        window.toggleLoadingOverlay(true);

        const isPlaying = ! player.video.paused();
        player.setPaused(true);

        if (srt) {
            // @ts-ignore
            let textTracks = player.video.textTracks();

            if (textTracks) {
                textTracks.tracks_.forEach(track => {
                    player.video.removeRemoteTextTrack(track);
                });
            }
        }

        if (translation_language) {
            if (await project.loadSrt(language, translation_language)) {
                setSRT(translation_language);

            } else {
                setSRT("");
                window.toggleLoadingOverlay(false);
                return;
            }

        } else {
            setSRT("");
        }

        player.setCurrentTime( player.currentTime() );

        if (isPlaying) {
            player.setPaused(false);
        }
        window.toggleLoadingOverlay(false);
    }


    return <>
        <div className="area-playback-settings d-flex align-items-center gap-1 px-0 py-0">
            {project?.files?.videos && (
                <div className="settings-group">
                    <div>{ t("pages.project.resolution") }</div>
                    <div>
                        <Form.Select
                            className="w-auto p-0"
                            onChange={e => changeVideoSrc(e.target.value)}
                        >
                            {project.files.videos.map((v, i) => {
                                return <option key={i} value={v.path}>{v.details.width}x{v.details.height}</option>;
                            })}
                        </Form.Select>
                    </div>
                </div>
            )}

            {project.languages && <>
                <div className="settings-group">
                    <div>{ t("common:language") }</div>
                    <div>
                        <Form.Select
                            className="w-auto p-0"
                            value={language}
                            onChange={e => changeLanguage(e.target.value)}
                        >
                            {Object.keys(project.languages).map(code => {
                                return <option key={code} value={code}>{ t(`common:lang_name.${code}`) }</option>;
                            })}
                        </Form.Select>
                    </div>
                </div>

                <div className="settings-group">
                    <div>{ t("pages.project.subtitles") }</div>
                    <div>
                        <Form.Select
                            className="w-auto p-0"
                            value={srt}
                            onChange={e => changeSrt(e.target.value)}
                        >
                            <option value="">{ t("common:off") }</option>

                            {project.languages[language]?.["subtitles"] && (
                                Object.entries(project.languages[language]["subtitles"]).map(([ trans_lang, sub ]) => {
                                    return <option key={trans_lang} value={trans_lang}>{ t(`common:lang_name.${trans_lang}`) }</option>;
                                })
                            )}
                        </Form.Select>
                    </div>
                </div>
            </>}
            {children}
        </div>
    </>;
}

export default AreaPlaybackSettings;

let CLIPBOARD: ClipboardHandler;

export abstract class ClipboardHandler {
    public abstract value: any;
}


const set = (handler: ClipboardHandler) => {
    CLIPBOARD = handler;
}

const get = () => {
    return CLIPBOARD;
}


export default { set, get };

import React, { useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Col, Form, Row } from "react-bootstrap";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faClapperboard, faFile, faFileExcel, faFileExport, faHouse, faListCheck, faSave, faShare } from "@fortawesome/free-solid-svg-icons";

import { browserHistory } from "@/ts/router";
import { apiRequest } from "@utils/api";
import { RsyncItemChanges, parseItemizeChanges } from "@utils/rsync";

import { getProject } from "@providers/project";
import { getVideoPlayer } from "@providers/videoplayer";
import ToastService from "@services/ToastService";
import ProjectService from "@services/ProjectService";

import Menu, { MenuData } from "@components/menu/menu";
import ConfirmModal from "@components/modals/confirm";

import ImgZsolesz from "@images/zsolesz.jpg";
import FormModal from "@components/modals/form";

type SyncModalData = {
    preview: RsyncItemChanges[];
    exportName: string;
    varHierarchyPath: string;
};

type ShareModelData = {
    visible: boolean;
    link?: string;
};


const ProjectMenu = () => {
    const { t } = useTranslation([ "mve", "common" ]);
    const project = getProject();
    const [showSyncModal, setShowSyncModal] = useState<SyncModalData>(null);
    const [showEditorUploadWarning, setShowEditorUploadWarning] = useState<SyncModalData>(null);
    const [showShareLinkModal, setShowShareLinkModal] = useState<ShareModelData>({ visible: false });
    const editorUploadCheckboxRef = useRef<HTMLInputElement>();

    const ProjectMenuData: MenuData[] =  [
        {
            title: t("pages.project.title").toUpperCase(),
            header: true
        },

        {
            title: t("common:actions.save"),
            icon: faSave,
            onClick: async () => {
                if (await ProjectService.save(project)) {
                    ToastService.success( t("common:actions.successfully_saved") );
                }
            }
        },

        {
            title: t("common:actions.export"),
            icon: faFileExport,
            isDisabled: () => project?.hasUnsavedChanges,
            subMenu: [
                {
                    title: t("common:actions.download_zip"),
                    onClick: async () => {
                        window.toggleLoadingOverlay(true, t("pages.project.actions.exporting"));

                        let res = await ProjectService.exportProject(project, true);

                        if (res.success) {
                            if (res.result.downloadUrl) {
                                window.open(res.result.downloadUrl, "_blank");
                            }
                        }

                        window.toggleLoadingOverlay(false);
                    }
                },
                {
                    title: t("pages.project.actions.export_to_prod"),
                    onClick: async () => {
                        window.toggleLoadingOverlay(true, t("pages.project.actions.exporting"));

                        let exportRes = await ProjectService.exportProject(project, false);

                        if (exportRes.success) {
                            let syncRes = await ProjectService.syncExportToProduction(project, exportRes.result.exportName, true);

                            if (syncRes.success) {
                                setShowSyncModal({
                                    exportName: exportRes.result.exportName,
                                    varHierarchyPath: syncRes.result.varHierarchyPath,
                                    preview: parseItemizeChanges(syncRes.result.output)
                                });
                            }
                        }

                        window.toggleLoadingOverlay(false);
                    }
                }
            ]
        }, {
            title: "Share",
            icon: faShare,
            onClick: () => setShowShareLinkModal({ visible: true })
        },

        { divider: true },

        {
            title: t("common:form.files"),
            icon: faFile,
            isDisabled: () => project?.hasUnsavedChanges,
            onClick: async () => {
                browserHistory.push(`/project/${project.id}/files`);
            }
        },

        {
            title: "Quizzes",
            icon: faListCheck,
            isDisabled: () => project?.hasUnsavedChanges,
            onClick: async () => {
                browserHistory.push(`/project/${project.id}/quizzes`);
            }
        },

        { divider: true },

        {
            title: "Premiere Pro",
            icon: faClapperboard,
            isDisabled: () => project?.hasUnsavedChanges,
            subMenu: [
                {
                    title: t("pages.project.actions.import_xml"),
                    icon: faFileExcel,
                    onClick: async () => {
                        let player = getVideoPlayer();

                        if (! player?.video)
                            return;

                        let input = $<HTMLInputElement>(`<input type="file" accept="xml" style="display: none">`)
                            .appendTo("body")
                            .trigger("click");

                        input.on("input", e => {
                            let file = input[0].files[0];

                            if (file) {
                                const data = new FormData();
                                data.append("file", file);

                                apiRequest("POST", `/project/${project.id}/premiere/loadxml`, data, {
                                    headers: {
                                        "Content-Type": "multipart/form-data"
                                    }
                                })
                                .then(res => {
                                    if (res.success) {
                                        location.reload();

                                    } else {
                                        ToastService.error(res.message);
                                    }
                                })
                                .catch(err => {
                                    ToastService.error(err.message);
                                });
                            }
                        });

                        $(window).one("focus", e => {
                            setTimeout(() => {
                                input.remove();
                            }, 100);
                        });

                        return true;
                    }
                }
            ]
        },

        { divider: true },

        {
            title: t("pages.project.actions.back_to_main"),
            icon: faHouse,
            onClick: async () => {
                browserHistory.push("/");
            }
        }
    ];

    const startRsync = async (exportName: string, startEditorUploadJob: boolean = false) => {
        window.toggleLoadingOverlay(true, t("pages.project.actions.uploading"));

        await ProjectService.syncExportToProduction(project, exportName, false, startEditorUploadJob);

        window.toggleLoadingOverlay(false);
    }

    const getRsyncItemColor = (type: string) => {
        switch (type) {
            case "create":
                return "#75d64f";
            case "update":
                return "#d6bb4f";
            case "delete":
            case "cannotDelete":
                return "#eb4034";
            default:
                return "#ffffff";
        }
    }


    return <>
        <Menu
            component={<FontAwesomeIcon icon={faBars} className="me-3" />}
            data={ProjectMenuData}
            align="start"
        />

        {showShareLinkModal.visible && (
            showShareLinkModal.link ? (
                <FormModal
                    title={ "Share link" }
                    fields={{
                        link: {
                            label: "URL",
                            type:  "text",
                            default: showShareLinkModal.link,
                            disabled: true
                        }
                    }}
                    buttons={[
                        {
                            text:     "Copy to clipboard",
                            variant:  "secondary",
                            callback: () => {
                                navigator.clipboard.writeText(showShareLinkModal.link);
                                ToastService.success("Link was succesfully copied to the clipboard!");
                            }
                        }
                    ]}
                    show={true}
                    hide={() => setShowShareLinkModal({ visible: false })}
                />

            ) : (
                <FormModal
                    title={ "Share link" }
                    fields={{
                        duration: {
                            label: "Expire after",
                            type: "select",
                            options: [
                                {
                                    label: "1 hour",
                                    value: "1"
                                },
                                {
                                    label: "1 day",
                                    value: "60"
                                },
                                {
                                    label: "1 week",
                                    value: "168"
                                }
                            ]
                        }
                    }}
                    buttons={[
                        {
                            text:     "Create",
                            variant:  "primary",
                            callback: (values: any) => {
                                apiRequest("POST", `/project/${project.id}/share/create`, {
                                    duration: values.duration
                                })
                                .then(res => {
                                    setShowShareLinkModal(p => ({ ...p, link: res.result }));
                                });
                                return false;
                            }
                        }
                    ]}
                    show={true}
                    hide={() => setShowShareLinkModal({ visible: false })}
                />
            )
        )}

        {showSyncModal && (
            <ConfirmModal
                text={
                    `${showSyncModal.varHierarchyPath}<br><br>` + (
                        showSyncModal.preview.length == 0
                        ? t("common:no_changes")
                        : showSyncModal.preview.map(item =>
                            `<span style="color: ${getRsyncItemColor(item.type)}">${ t(`common:rsync.types.${item.type}`) } ${item.path}</span>`
                        ).join("<br>")
                    )
                }
                component={
                    showSyncModal.preview.length > 0
                        ? <Form.Check ref={editorUploadCheckboxRef} type="switch" label={ t("pages.project.actions.prod_auto_upload") } className="mt-2" />
                        : ""
                }
                hide={_ => setShowSyncModal(null)}
                callback={async ({ accepted }) => {
                    if (accepted) {
                        let editorUploadEnabled = editorUploadCheckboxRef?.current?.checked ?? false;

                        if (editorUploadEnabled) {
                            setShowEditorUploadWarning(showSyncModal);

                        } else {
                            await startRsync(showSyncModal.exportName, false);
                        }
                    }
                }}
            />
        )}

        {showEditorUploadWarning && (
            <ConfirmModal
                title={ t("common:form.are_you_sure") }
                confirmText={ t("pages.project.actions.skip_upload") }
                cancelText={ t("pages.project.actions.yes_upload") }
                component={
                    <Row>
                        <Col>
                            <Trans
                                t={t}
                                i18nKey="pages.project.actions.prod_upload_warning"
                            />
                        </Col>

                        <Col>
                            <img src={ImgZsolesz} className="w-100" />
                        </Col>
                    </Row>
                }
                hide={_ => setShowEditorUploadWarning(null)}
                callback={async ({ accepted: skipUpload }) => {
                    await startRsync(showEditorUploadWarning.exportName, !skipUpload);
                }}
            />
        )}
    </>;
}


export default ProjectMenu;

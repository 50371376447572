import React, { useRef, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackwardFast, faBackwardStep, faForward, faForwardFast, faForwardStep, faPause, faPlay, faVolumeHigh, faVolumeXmark } from "@fortawesome/free-solid-svg-icons";
import { ControlledMenu, FocusableItem } from "@szhsin/react-menu";

import Tooltip from "@components/tippy/tooltip";

import Project from "@models/project";
import VideoPlayer from "@models/videoplayer";
import { useProjectPageContext } from "../project";

type Props = {
    project: Project;
    player: VideoPlayer;
    disabled?: boolean;
}


const AreaControls = ({ project, player, disabled }: Props) => {
    const { t } = useTranslation([ "mve" ]);
    const { togglePaused, moveCaretToMS } = useProjectPageContext();
    const timeRef = useRef<HTMLSpanElement>();
    const timeInputRef = useRef<HTMLInputElement>();
    const [isTimeEditOpened, setTimeEditOpened] = useState(false);

    const toggleMute = () => {
        player?.toggleMute?.();
        window.projectPageForceRerender();
    }
    
    return (
        <div className={classNames("area-controls d-flex align-items-center", { "area-disabled": disabled })}>
            <span
                ref={timeRef}
                id="controls-time"
                className="time"
                onClick={_ => setTimeEditOpened(true)}
            >
                00:00.000
            </span>

            {isTimeEditOpened && (
                <ControlledMenu
                    state="open"
                    anchorRef={timeRef}
                    align="center"
                    direction="top"
                    theming="dark"
                    onClose={_ => setTimeEditOpened(false)}
                >
                    <FocusableItem className="px-2 py-0">
                        {({ ref }) => (
                            <div ref={ref} className="d-flex align-items-center gap-2">
                                <input ref={timeInputRef} type="text" defaultValue={player.getTimeString()} />

                                <FontAwesomeIcon icon={faForward} className="control-btn"
                                    onClick={e => {
                                        let ms = player.timeStringToMS(timeInputRef.current.value);

                                        if (ms) {
                                            player.setCurrentTime(ms);
                                            moveCaretToMS(ms);
                                            setTimeEditOpened(false);
                                        }
                                    }}
                                />
                            </div>
                        )}
                    </FocusableItem>
                </ControlledMenu>
            )}

            <div className="controls d-flex align-items-center gap-3">
                <Tooltip text={ t("pages.project.x_sec", { count: -1 }) + " (H)" }>
                    <FontAwesomeIcon
                        icon={faBackwardFast}
                        className="control-btn"
                        onClick={_ => player.setCurrentTime(-1000, true)}
                    />
                </Tooltip>
                <Tooltip text={ t("pages.project.x_frame", { count: -1 }) + " (Left)" }>
                    <FontAwesomeIcon
                        icon={faBackwardStep}
                        className="control-btn"
                        onClick={_ => player.setCurrentTime(-1000 / project.frame_rate, true)}
                    />
                </Tooltip>
                
                <Tooltip text={player?.video?.paused?.() ? `${ t("pages.project.actions.play") } (J)` : `${ t("pages.project.actions.pause") } (J)`}>
                    <FontAwesomeIcon
                        icon={player?.video?.paused?.() ? faPlay : faPause}
                        className="control-btn"
                        onClick={togglePaused}
                    />
                </Tooltip>

                <Tooltip text={ t("pages.project.x_frame", { count: 1 }) + " (Right)" }>
                    <FontAwesomeIcon
                        icon={faForwardStep}
                        className="control-btn"
                        onClick={_ => player.setCurrentTime(1000 / project.frame_rate, true)}
                    />
                </Tooltip>
                <Tooltip text={ t("pages.project.x_sec", { count: 1 }) + " (K)" }>
                    <FontAwesomeIcon
                        icon={faForwardFast}
                        className="control-btn"
                        onClick={_ => player.setCurrentTime(1000, true)}
                    />
                </Tooltip>
            </div>

            <div className="volume d-flex align-items-center gap-2">
                {player?.video && (
                    <input
                        type="range"
                        defaultValue={player?.video?.volume()}
                        min={0}
                        max={1}
                        step={0.01}
                        onChange={e => player?.setVolume?.(parseFloat(e.target.value))}
                    />
                )}
                
                <Tooltip text={ t("pages.project.mute") + " (M)" }>
                    <FontAwesomeIcon
                        icon={player?.video?.muted?.() ? faVolumeXmark : faVolumeHigh}
                        className="mute"
                        onClick={toggleMute}
                    />
                </Tooltip>
            </div>
        </div>
    );
}

export default AreaControls;

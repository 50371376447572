import { CSSProperties } from "@utils/styles";

enum AnchorPosition {
    LeftTop      = "left-top",
    CenterTop    = "center-top",
    RightTop     = "right-top",
  
    LeftCenter   = "left-center",
    CenterCenter = "center-center",
    RightCenter  = "right-center",

    LeftBottom   = "left-bottom",
    CenterBottom = "center-bottom",
    RightBottom  = "right-bottom"
}

type AnchorCSSStyleType = "text" | "position";


const getAnchorCssStyle = (anchor: AnchorPosition, styleType: AnchorCSSStyleType = "text"): CSSProperties => {
    let [ horizontal, vertical ] = anchor.split("-");

    if (styleType === "text") {
        switch (vertical) {
            case "top":
                vertical = "start";
                break;
    
            case "bottom":
                vertical = "end";
                break;
        }
    
        return {
            justifyContent: horizontal,
            alignItems:     vertical,
            textAlign:      horizontal
        };

    } else if (styleType === "position") {
        let [ left, top ] = [ 0, 0 ];
        
        switch (horizontal) {
            case "center":
                left = 50;
                break;
    
            case "right":
                left = 100;
                break;
        }

        switch (vertical) {
            case "center":
                top = 50;
                break;
    
            case "bottom":
                top = 100;
                break;
        }

        return {
            left: `${left}%`,
            top:  `${top}%`
        };
    }
}


/* 
    leftTopValue: a négyzet bal felső sarka (alap anchor állapot)
    rightBottomOffset: az offset, amennyivel el kell tolni a leftTopValue-t, hogy a négyzet jobb alsó sarkát kapjuk
    anchor: az anchor állapot, amire offsetelni kell az értékeket
*/
const getAnchorOffsettedValues = (leftTopValues: [number, number], rightBottomOffsets: [number, number], anchor: AnchorPosition) => {
    const [ horizontal, vertical ] = anchor.split("-");

    const [ leftMaxOffset, topMaxOffset ] = rightBottomOffsets;
    let   [ left, top ]                   = leftTopValues;

    switch (horizontal) {
        case "center":
            left += leftMaxOffset / 2;
            break;

        case "right":
            left += leftMaxOffset;
            break;
    }

    switch (vertical) {
        case "center":
            top += topMaxOffset / 2;
            break;

        case "bottom":
            top += topMaxOffset;
            break;
    }

    return [ left, top ];
}


const inverseAnchorPosition = (anchor: AnchorPosition) => {
    switch(anchor) {
        case AnchorPosition.LeftTop:
            return AnchorPosition.RightBottom;

        case AnchorPosition.LeftCenter:
            return AnchorPosition.RightCenter;
        
        case AnchorPosition.LeftBottom:
            return AnchorPosition.RightTop;

        case AnchorPosition.CenterTop:
            return AnchorPosition.CenterBottom;

        case AnchorPosition.CenterCenter:
            return AnchorPosition.CenterCenter;
        
        case AnchorPosition.CenterBottom:
            return AnchorPosition.CenterTop;

        case AnchorPosition.RightTop:
            return AnchorPosition.LeftBottom;

        case AnchorPosition.RightCenter:
            return AnchorPosition.LeftCenter;
        
        case AnchorPosition.RightBottom:
            return AnchorPosition.LeftTop;
    }
}


export default AnchorPosition;

export {
    getAnchorCssStyle, AnchorCSSStyleType,
    getAnchorOffsettedValues,
    inverseAnchorPosition
};

import React from "react";
import { useTranslation } from "react-i18next";
import { useEventListener } from "usehooks-ts";

import { useProjectPageContext } from "./project";

import SubtitleEditorService from "@services/SubtitleEditorService";
import CommandService from "@services/CommandService";
import ProjectService from "@services/ProjectService";
import ToastService from "@services/ToastService";
import ClipCopyHandler from "@services/clipboard_handlers/clip_copy_handler";
import ClipboardService from "@services/ClipboardService";

import Project from "@models/project";
import VideoPlayer from "@models/videoplayer";


type Props = {
    project: Project;
    player: VideoPlayer;
}


const ProjectPageKeyBinds = ({ project, player }: Props) => {
    const { t } = useTranslation([ "common" ]);
    const { togglePaused, toggleRuler, setFullscreen } = useProjectPageContext();

    useEventListener("keydown", async ({ code, ctrlKey, shiftKey, metaKey }: KeyboardEvent) => {
        if (document.activeElement && document.activeElement !== document.body)
            return false;

        let isCtrlOrCmd = ctrlKey || metaKey;

        switch (code) {
            case "Space":
            case "KeyJ":
                return togglePaused();
            
            case "KeyR":
                return toggleRuler()
            
            case "KeyH":
                player.setCurrentTime(-1000, true);
                window.projectPageForceRerender();
                return;
            
            case "KeyK":
                player.setCurrentTime(1000, true);
                window.projectPageForceRerender();
                return;
            
            case "ArrowLeft":
                player.setCurrentTime(-1000 / project.frame_rate, true);
                window.projectPageForceRerender();
                return;
            
            case "ArrowRight":
                player.setCurrentTime(1000 / project.frame_rate, true);
                window.projectPageForceRerender();
                return;
            
            case "KeyM":
                player.toggleMute();
                window.projectPageForceRerender();
                return;
            
            case "KeyF":
                let fullscreen = player.toggleFullscreen;
                setFullscreen(fullscreen)
                return fullscreen;
            
            case "KeyS":
                if (isCtrlOrCmd && shiftKey) {
                    if (await ProjectService.save(project)) {
                        ToastService.success( t("common:actions.successfully_saved") );
                    }
                }
                return;
            
            case "KeyY": // It's Z
                return isCtrlOrCmd && CommandService.undo();

            case "KeyZ": // It's Y
                return isCtrlOrCmd && CommandService.redo();

            case "KeyC":
                if (isCtrlOrCmd && project.selectedClip) {
                    ClipboardService.set( new ClipCopyHandler(project.selectedClip) );
                }
                break;

            case "KeyV":
                if (isCtrlOrCmd && project.selectedChannel) {
                    const clipboardHandler = ClipboardService.get();

                    if (clipboardHandler instanceof ClipCopyHandler) {
                        clipboardHandler.paste(project.selectedChannel, player.currentTime());
                    }
                }
                break;

            case "Escape":
                return SubtitleEditorService.setTarget(null);
            
            
        }
    });

    return <></>;
}

export default ProjectPageKeyBinds;

import React, { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faLock, faPlus, faUnlock } from "@fortawesome/free-solid-svg-icons";

import ChannelModel, { ChannelType } from "@models/channel";
import Tooltip from "@components/tippy/tooltip";
import { useProjectPageContext } from "@pages/project/project";
import Color from "@clip/properties/color";

type ChannelProps = {
    channel: ChannelModel;
    className?: string;
    onClick?: MouseEventHandler;
}


const ChannelControls = ({ channel, className, ...props }: ChannelProps) => {
    const { t } = useTranslation([ "mve", "common" ]);

    const toggleLock = () => {
        channel.locked = !channel.locked;
        window.projectPageForceRerender();    
    }

    const toggleHide = () => {
        channel.hidden = !channel.hidden;
        window.projectPageForceRerender();
    }
    
    return <>
        <div
            {...props}
            className={classNames("channel-controls d-flex", className, { "selected": channel.selected, "marker-channel-control": channel.type === ChannelType.MARKER })}
            onMouseDown={e => {
                if (channel.type === ChannelType.NORMAL){
                    channel.project.setSelectedChannel(channel)
                }
                window.projectPageForceRerender();
            }}
        >
            <div className="d-flex align-items-center justify-content-center gap-3 px-2" style={{ flex: 1 }}>
                {channel.type === ChannelType.NORMAL && <>
                    <Tooltip text={channel.locked ? t("pages.project.actions.unlock_channel") : t("pages.project.actions.lock_channel")}>
                        <FontAwesomeIcon icon={channel.locked ? faLock : faUnlock} className={classNames("control-btn", { "active": channel.locked })} onClick={toggleLock} />
                    </Tooltip>

                    <Tooltip text={channel.hidden ? t("pages.project.actions.hide_channel") : t("pages.project.actions.unhide_channel")}>
                        <FontAwesomeIcon icon={channel.hidden ? faEyeSlash : faEye} className={classNames("control-btn", { "active": channel.hidden })} onClick={toggleHide} />
                    </Tooltip>
                </>}
            </div>
        </div>
    </>;
};

export default ChannelControls;

import { mergeStyles } from "@utils/styles";
import { getSubtitleManager } from "@providers/subtitlemanager";
import SubtitleEditorService from "@services/SubtitleEditorService";
import { Set } from "@decorators/set";
import { OnChange } from "@decorators/onchange";

import AnchorPosition from "@enums/anchorposition";

import { ClipBackendPayload } from "@models/project";
import Channel from "@models/channel";
import Clip, { ClipType } from "./clip";

import Position from "@clip/properties/position";
import Scale from "@clip/properties/scale";
import Text from "@clip/properties/text";
import Rotation from "@clip/properties/rotation";
import Color from "@clip/properties/color";
import Font from "@clip/properties/font";
import Anchor from "@clip/properties/anchor";
import NumberProperty from "@clip/properties/number";
import Switch from "@clip/properties/switch";

export default class TextClip extends Clip {
    public type: ClipType = "TextClip";

    @OnChange((clip: Clip) => {
        clip.updateElementStyle();
        SubtitleEditorService.updateRect();
    })
    public position: Position = new Position(this);

    @Set({ x: 30, y: 15 })
    @OnChange((clip: TextClip) => {
        clip.updateElementStyle();
        SubtitleEditorService.updateRect();
    })
    public scale: Scale = new Scale(this);

    @OnChange((clip: TextClip) => {
        clip.updateElementStyle();
        SubtitleEditorService.updateRect();
    })
    public rotation: Rotation = new Rotation(this);

    @Set({ value: "Sample text" })
    @OnChange((clip: TextClip) => {
        clip.updateElementStyle();
    })
    public text: Text = new Text(this);

    @Set({ i18nKey: "pages.project.property_name.line_height", value: 1.5, min: 0.5, max: 3, step: 0.1 })
    @OnChange((clip: TextClip) => {
        clip.updateElementStyle();
    })
    public lineHeight: NumberProperty = new NumberProperty(this);

    @OnChange((clip: TextClip) => {
        clip.updateElementStyle();
    })
    public color: Color = new Color(this);

    @OnChange((clip: TextClip) => {
        clip.updateElementStyle();
    })
    public font: Font = new Font(this);

    @Set({ i18nKey: "pages.project.property_name.font_size", value: 35, min: 12, max: 120 })
    @OnChange((clip: TextClip) => {
        clip.updateElementStyle();
    })
    public fontSize: NumberProperty = new NumberProperty(this);

    @Set({ i18nKey: "pages.project.property_name.text_anchor", value: AnchorPosition.CenterCenter })
    @OnChange((clip: TextClip) => {
        clip.updateElementStyle();
        SubtitleEditorService.updateRect();
    })
    public anchor: Anchor = new Anchor(this);

    @Set({ i18nKey: "pages.project.property_name.content_fit" })
    @OnChange((clip: TextClip) => {
        clip.updateElementStyle();
        SubtitleEditorService.updateRect();

        window.projectPageForceRerender(); // toggle sizeContentFitAnchor
    })
    public sizeContentFit: Switch = new Switch(this);

    @Set({
        i18nKey: "pages.project.property_name.content_anchor",
        value:   AnchorPosition.CenterCenter,
        hidden: (clip) => ! clip.sizeContentFit.value
    })
    @OnChange((clip: TextClip) => {
        clip.updateElementStyle();
        SubtitleEditorService.updateRect();
    })
    public sizeContentFitAnchor: Anchor = new Anchor(this);


    constructor(channel: Channel) {
        super(channel);

        this.editableProperties.push(
            "position", "scale", "rotation", "text", "lineHeight",
            "color", "font", "fontSize", "anchor",
            "sizeContentFit", "sizeContentFitAnchor"
        );
    }
    
    public exportPayload() {
        let payload = super.exportPayload();
        payload.data.position             = this.position.exportPayload();
        payload.data.scale                = this.scale.exportPayload();
        payload.data.rotation             = this.rotation.exportPayload();
        payload.data.text                 = this.text.exportPayload();
        payload.data.lineHeight           = this.lineHeight.exportPayload();
        payload.data.color                = this.color.exportPayload();
        payload.data.font                 = this.font.exportPayload();
        payload.data.fontSize             = this.fontSize.exportPayload();
        payload.data.anchor               = this.anchor.exportPayload();
        payload.data.sizeContentFit       = this.sizeContentFit.exportPayload();
        payload.data.sizeContentFitAnchor = this.sizeContentFitAnchor.exportPayload();

        return payload;
    }

    public applyPayload(payload: ClipBackendPayload) {
        super.applyPayload(payload);

        if (payload.type === "TextClip") {
            this.position.applyPayload(payload.data.position);
            this.scale.applyPayload(payload.data.scale);
            this.rotation.applyPayload(payload.data.rotation);
            this.text.applyPayload(payload.data.text);
            this.lineHeight.applyPayload(payload.data.lineHeight);
            this.color.applyPayload(payload.data.color);
            this.font.applyPayload(payload.data.font);
            this.fontSize.applyPayload(payload.data.fontSize);
            this.anchor.applyPayload(payload.data.anchor);
            this.sizeContentFit.applyPayload(payload.data.sizeContentFit);
            this.sizeContentFitAnchor.applyPayload(payload.data.sizeContentFitAnchor);
        }
    }


    public getSubtitleHTML = () => {
        return `<span>${this.text.value}</span>`;
    }

    public setSubtitleElementStyle = (element: JQuery<HTMLElement>) => {
        super.setSubtitleElementStyle(element);

        let [ subtitleManager ] = getSubtitleManager();
        let fontSize            = subtitleManager.getSubtitleScaleRatio() * this.fontSize.value;
        
        let wrapperElement = element.find(".subtitle-wrapper");
        let contentElement = element.find(".subtitle-content");

        wrapperElement.css({
            fontSize:   fontSize,
            lineHeight: this.lineHeight.value
        });
        
        let styles = mergeStyles([
            {
                content: {
                    fontSize: "inherit",
                    lineHeight: "inherit"
                }
            },
            { parent: this.position.toCSS() },
            { parent: this.scale.toCSS() },
            { parent: this.rotation.toCSS() },
            { parent: this.sizeContentFitAnchor.toCSS() },
            { content: this.color.toCSS() },
            { content: this.font.toCSS() },
            { content: this.anchor.toCSS() }
        ]);

        if (styles.parent) {
            element.css(styles.parent);
        }
        if (styles.wrapper) {
            wrapperElement.css(styles.wrapper);
        }
        if (styles.content) {
            contentElement.css(styles.content);
        }

        element.toggleClass("content-fit", this.sizeContentFit.value);
        contentElement.html(this.getSubtitleHTML());

        this.initEffects();

        subtitleManager.loadWebFont(this.font.value);
    }
}

import i18n from "@/ts/i18n";

import Project, { ProjectBackendPayload } from "@models/project";
import { ApiResponse, apiRequest } from "@utils/api";
import ToastService from "./ToastService";
import SocketService from "./SocketService";


let IN_PROGRESS = false;

const create = (name: string, description: string = "", azon: string, groupid?: number): Promise<Project> => new Promise(resolve => {
    apiRequest<ProjectBackendPayload>("POST", "/project/create", { name, description, azon, groupid })
        .then(res => {
            if (res.success) {
                const project = new Project();
                project.applyPayload(res.result);

                resolve(project);

            } else {
                ToastService.error(res.message ?? i18n.t("common:something_went_wrong"));
                resolve(null);
            }
        });
});

const destroy = (ids: number[]): Promise<boolean> => new Promise(resolve => {
    apiRequest<ProjectBackendPayload>("POST", `/project/delete`, {"ids":ids})
        .then(res => {
            resolve(res.success);
        });
});

const load = (id: number): Promise<Project> => new Promise(resolve => {
    apiRequest<ProjectBackendPayload>("GET", `/project/${id}/get`)
        .then(res => {
            if (res.success) {
                const project = new Project();
                project.applyPayload(res.result);

                resolve(project);
                
            } else {
                ToastService.error(res.message ?? i18n.t("common:something_went_wrong"));
                resolve(null);
            }
        });
});

const save = (project: Project): Promise<boolean> => new Promise(resolve => {
    if (IN_PROGRESS)
        return resolve(false);
    
    if (project.getPrimaryLanguage() !== project.activeLanguage) {
        ToastService.error(i18n.t("pages.project.save_foreign_lang_warning"));
        return resolve(false);
    }

    IN_PROGRESS = true;
    
    apiRequest("POST", `/project/${project.id}/save`, {
        data: JSON.stringify( project.exportPayload() )
    })
    .then(res => {
        IN_PROGRESS = false;

        if (res.success) {
            let new_assigned_ids = res?.result?.new_assigned_ids;

            if (new_assigned_ids) {
                // replace temporary channel ids with permanent ids
                for (let old_id in new_assigned_ids["channels"]) {
                    let new_id = new_assigned_ids["channels"][old_id];
                    project.findChannel(parseInt(old_id)).id = parseInt(new_id);
                }

                // replace temporary clip ids with permanent ids
                for (let old_id in new_assigned_ids["clips"]) {
                    let new_id = new_assigned_ids["clips"][old_id];
                    project.findClip(parseInt(old_id)).id = parseInt(new_id);
                }

                project.hasUnsavedChanges = false;
                window.projectPageForceRerender();

                return resolve(true);
            }
        }

        ToastService.error(i18n.t("common:actions.failed_to_save"));

        resolve(false);
    });
});

const clone = (cloneDatas : {id: number, newName?: string, newAzon?: string}[]): Promise<ProjectBackendPayload> => new Promise(resolve => {
    apiRequest<ProjectBackendPayload>("POST", `/project/clone`, { "data": cloneDatas})
        .then(res => {
            if (! res.success)
                return resolve(null);
            
            resolve(res.result);
        });
});


type ProjectExportResponse = ApiResponse<{
    downloadUrl?: string;
    exportName?: string;
}>;

const exportProject = (project: Project, makeDownloadUrl: boolean = true): Promise<ProjectExportResponse> => new Promise(resolve => {
    if (IN_PROGRESS)
        return resolve({ success: false });

    IN_PROGRESS = true;

    SocketService.listenUserEvents("project.exported", ({ data }) => {
        IN_PROGRESS = false;
        resolve(data);
    }, true);

    apiRequest("POST", `/project/${project.id}/export`, { makeDownloadUrl })
        .then(res => {
            if (! res.success) {
                IN_PROGRESS = false;
                ToastService.error(res.message ?? i18n.t("common:something_went_wrong"));
                return resolve({ success: false });
            }
        })
        .catch(err => {
            IN_PROGRESS = false;
            return resolve({ success: false });
        });
});

type ProjectSyncResponse = ApiResponse<{
    output: string[];
    varHierarchyPath: string;
}>;

const syncExportToProduction = (
    project: Project,
    exportName: string,
    dryrun: boolean = true,
    startEditorUploadJob: boolean = false
): Promise<ProjectSyncResponse> => new Promise(resolve => {
    if (IN_PROGRESS)
        return resolve({ success: false });

    IN_PROGRESS = true;

    apiRequest("POST", `/project/${project.id}/sync-export`, { exportName, dryrun, startEditorUploadJob })
        .then(res => {
            if (! res.success) {
                ToastService.error(res.message ?? i18n.t("common:something_went_wrong"));
            }
            IN_PROGRESS = false;
            return resolve(res);
        })
        .catch(err => {
            IN_PROGRESS = false;
            return resolve({ success: false });
        });
});

const edit = (id: number, args: { name: string, description: string, azon: string }): Promise<boolean> => new Promise(resolve => {
    apiRequest("POST", `/project/${id}/edit`, args)
        .then(res => {
            if (! res.success)
                ToastService.error(res.message ?? i18n.t("common:something_went_wrong"));

            resolve(res.success);
        });
});

const setGroup = (ids: number[], groupid: number): Promise<boolean> => new Promise(resolve => {
    apiRequest("POST", `/project/set-group`, { "ids": ids, "groupid":groupid })
        .then(res => {
            if (! res.success)
                ToastService.error(res.message ?? i18n.t("common:something_went_wrong"));

            resolve(res.success);
        });
});


export default {
    create, destroy,
    load, save, clone,
    syncExportToProduction, exportProject,
    edit,
    setGroup
};

import classNames from "classnames";
import React, { createRef, useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import Form from "react-bootstrap/Form";

type ModalProps = {
    title: string;
    fields: {
        [key: string]: {
            label: string;
            type: "text" | "textarea" | "number" | "file" | "select" | "checkbox",
            default?: string | number,
            disabled?: boolean,
            options?: {
                label: string,
                value: string
            }[],
            checked?: boolean,
            accept?: string,
            min?: number,
            max?: number
        }
    }
    buttons: {
        text: string,
        variant: "primary" | "secondary",
        callback: (data: any) => void | boolean | Promise<void | boolean>,
        loadingOnClick?: boolean
    }[]
    show?: boolean;
    hide: Function;
    autoSubmit?: boolean;
};

const FormModal = ({ title, fields, buttons, show = true, hide, autoSubmit = false }: ModalProps) => {
    let [loadingButton, setLoadingButton] = useState<number>(null);

    let fieldKeys = Object.keys(fields);
    let refs = {};
    fieldKeys.forEach(key => {
        refs[key] = createRef();
    })

    const onSubmit = async (buttonIndex: number) => {
        let values = {};

        for (let key of fieldKeys) {
            let type  = fields[key].type;
            let value = null;

            switch (type) {
                case "file":
                    value = refs[key].current.files;
                    break;
                case "checkbox":
                    value = refs[key].current.checked;
                    break;
                default:
                    value = refs[key].current.value;
                    break;
            }

            if (value === null)
                return false;

            values[key] = value;
        }

        let btn = buttons[buttonIndex];

        if (btn.loadingOnClick === undefined || btn.loadingOnClick) {
            setLoadingButton(buttonIndex);
        }

        let ret = await btn.callback(values);

        if (ret !== false) {
            hide();
        }
    };

    useEffect(() => {
        if (autoSubmit){
            let values = [];
            for (let key of fieldKeys){
                values[key] = null;
            }
            buttons[0].callback(values)
            hide()
        }
        return () => {
            setLoadingButton(null);
        };
    })

    return (
        <Modal show={show} centered onHide={() => hide()}>
            <Modal.Header closeButton onClick={() => hide()}>
                <Modal.Title>
                    { title }
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {fieldKeys.map((key, i) => {
                    let values  = fields[key];
                    let control = null;

                    let label = (
                        <Form.Label>
                            { values.label }
                        </Form.Label>
                    );

                    switch (values.type) {
                        case "text":
                            control = <Form.Control ref={ refs[key] } type={values.type} defaultValue={values.default ?? ""} disabled={values.disabled || loadingButton !== null} />
                            break;
                        case "textarea":
                            control = <textarea ref={ refs[key] } defaultValue={values.default ?? ""} disabled={values.disabled || loadingButton !== null} />;
                            break;
                        case "number":
                            control = <Form.Control ref={ refs[key] } type={values.type} defaultValue={values.default ?? 0} min={values.min} max={values.max} disabled={values.disabled || loadingButton !== null} />
                            break;
                        case "file":
                            control = <Form.Control ref={ refs[key] } type={values.type} disabled={values.disabled || loadingButton !== null} />
                            break;
                        case "select":
                            control = (
                                <Form.Select ref={ refs[key] } disabled={values.disabled || loadingButton !== null}>
                                    { values.options.map((option, i) => <option key={i} value={option.value}>{ option.label }</option>) }
                                </Form.Select>
                            );
                            break;
                        case "checkbox":
                            label   = null;
                            control = (
                                <Form.Check ref={ refs[key] } type="switch" label={values.label} defaultChecked={values.checked} disabled={values.disabled || loadingButton !== null} />
                            );
                            break;
                    }

                    return (
                        <div key={key} className={classNames({ "mt-2": i > 0 })}>
                            { label }
                            { control }
                        </div>
                    );
                })}
            </Modal.Body>

            <Modal.Footer>
                {buttons.map((btn, index) => {
                    let isLoading = (btn.loadingOnClick === undefined || btn.loadingOnClick) && loadingButton === index;

                    return (
                        <Button key={index} onClick={() => onSubmit(index)} disabled={isLoading} variant={btn.variant}>
                            { isLoading && <Spinner size="sm" className="me-1" /> }
                            { btn.text }
                        </Button>
                    );
                })}
            </Modal.Footer>
        </Modal>
    );
}

export default FormModal;

import { ClipboardHandler } from "../ClipboardService";

import { getProject } from "@providers/project";
import Clip from "@models/clip/clip";
import Channel, { getClipClassFromType } from "@models/channel";


export default class ClipCopyHandler extends ClipboardHandler {
    public value: Clip;

    constructor(clip: Clip) {
        super();
        this.value = clip;
    }

    public paste(channel: Channel, startTime: number) {
        const project = getProject();
        
        let payload = this.value.exportPayload();
        payload.id  = project.nextTempID();
        
        let clipClass = getClipClassFromType(this.value.type);
        let newClip   = new clipClass(this.value.channel);
        
        newClip.applyPayload(payload);
        
        newClip.startTime = startTime;
        channel.addClip(newClip);
        
        window.projectPageForceRerender();
    }
}

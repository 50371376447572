import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightFromBracket, faArrowRightToBracket, faArrowUp91, faBorderStyle, faBullseye, faCloud, faFont, faHeading, faListUl, faPalette, faSquareRootVariable } from "@fortawesome/free-solid-svg-icons";

import SubtitleEditorService from "@services/SubtitleEditorService";

import Project from "@models/project";
import { createClipEffectFromType } from "@models/clip/clip";

type Props = {
    project: Project;
    disabled?: boolean;
}

type DraggingData = {
    clone?: HTMLDivElement;
    offsetX: number;
    offsetY: number;
}

const EFFECTS = [
    {
        icon: faHeading,
        effectType: "TitleEffect"
    },
    {
        icon: faPalette,
        effectType: "BackgroundEffect"
    },
    {
        icon: faArrowRightFromBracket,
        effectType: "FadeInEffect"
    },
    {
        icon: faArrowRightToBracket,
        effectType: "FadeOutEffect"
    },
    {
        icon: faBullseye,
        effectType: "TargetLineEffect"
    },
    {
        icon: faListUl,
        effectType: "ListSlideUpEffect"
    },
    {
        icon: faArrowUp91,
        effectType: "CounterEffect"
    },
    {
        icon: faSquareRootVariable,
        effectType: "LatexEffect"
    },
    {
        icon: faFont,
        effectType: "UppercaseEffect"
    },
    {
        icon: faCloud,
        effectType: "ShadowEffect"
    },
    {
        icon: faBorderStyle,
        effectType: "BorderEffect"
    }
];


const AreaEffects = ({ project, disabled }: Props) => {
    const { t } = useTranslation([ "mve" ]);
    const [movingEvent, setMovingEvent] = useState<DraggingData>();

    const onMoveHandler = (e: MouseEvent) => {
        let { pageX, pageY } = e;
        const { clone, offsetX, offsetY } = movingEvent;

        clone.style.left = `${pageX - offsetX}px`;
        clone.style.top  = `${pageY - offsetY}px`;
    }

    const onMoveEnd = (e: MouseEvent) => {
        const targetClip = $(e.target).closest(".clip");

        if (targetClip.length === 1) {
            let clipID = parseInt(targetClip.data("id"));
            let clip   = project.findClip(clipID);

            if (clip) {
                let effectType = $(movingEvent.clone).data("effect-type") as string;
                let effect      = createClipEffectFromType(effectType);

                if (effect) {
                    clip.addEffect(effect);

                    window.projectPageForceRerender();
                    SubtitleEditorService.setTarget(null);
                }
            }
        }

        movingEvent?.clone?.remove();

        setMovingEvent(undefined);
    }

    const onMoveStart = (e: any) => {
        const target = e.currentTarget as HTMLDivElement;
        const rect   = target.getBoundingClientRect();

        let clone = target.cloneNode(true) as HTMLDivElement;
        clone.classList.add("dragging");

        clone.style.left   = `${rect.left}px`;
        clone.style.top    = `${rect.top}px`;
        clone.style.width  = `${rect.width}px`;
        clone.style.height = `${rect.height}px`;
        
        target.after(clone);
        
        let offsetX = e.pageX - rect.left;
        let offsetY = e.pageY - rect.top;

        setMovingEvent({ clone, offsetX, offsetY });
    }

    const onMouseDown = (e: any) => {
        if (e.button !== 0)
            return;

        onMoveStart(e);
    }

    useEffect(() => {
        if (movingEvent) {
            window.addEventListener("mousemove", onMoveHandler);
            window.addEventListener("mouseup", onMoveEnd);
        }
        
        return () => {
            window.removeEventListener("mousemove", onMoveHandler);
            window.removeEventListener("mouseup", onMoveEnd);
        };
    }, [ movingEvent ]);

    useEffect(() => {
        $(".effect").on("mousedown", onMouseDown);

        return () => {
            $(".effect").off("mousedown", onMouseDown);
        }
    }, []);

    return (
        <div className={classNames("area-effects", { "area-disabled": disabled })}>
            <span className="title">{ t("pages.project.effects") }</span>

            <div className="effects">
                {EFFECTS.map((effect, i) => (
                    <div
                        key={i}
                        className={classNames("effect", { "highlighted": project.selectedClip?.hasEffect?.(effect.effectType) })}
                        data-effect-type={effect.effectType}
                    >
                        <div className="icon">
                            <FontAwesomeIcon icon={effect.icon} />
                        </div>
                        <div className="name">
                            { t(`pages.project.effect_name.${ effect.effectType }`) }
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default AreaEffects;

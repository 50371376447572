import React, { useState } from "react";
import { useUpdateEffect } from "usehooks-ts";

import ClipPropertyChangeCommand from "@services/commands/clip_property_change";
import ClipProperty from "@models/clipproperty";

import NumberInput from "@components/inputs/number";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";

type MultiNumberPropType = {
    key: string;
    label: string;
    value: number;
}

export default class MultiNumberProperty extends ClipProperty {
    public title: string = "Number";
    public min: number = 0;
    public max: number = null;
    public step: number = 1;
    
    public values: MultiNumberPropType[];
    public linked: boolean = true;

    public exportPayload() {
        return {
            ...super.exportPayload(),
            values: this.values,
            linked: this.linked
        };
    }


    public set(values: MultiNumberPropType[], linked: boolean) {
        const cmd = new ClipPropertyChangeCommand(this.parent, this, { values, linked });
        cmd.execute();
    }

    public getPropertyEditorComponent() {
        return (
            <this.PropertyEditorWrapperComponent property={this}>
                <this.PropertyEditor />    
            </this.PropertyEditorWrapperComponent>
        );
    }
    
    protected PropertyEditor = () => {
        const [values, setValues] = useState(this.values);
        const [linked, setLinked] = useState(this.linked);

        useUpdateEffect(() => {
            this.set(values, linked);
        }, [ values ]);

        const updateValues = (value: number, label: string, linked: boolean) => {
            if (linked){
                setValues(prev => {
                    return prev.map((v) => {
                        v.value = value;
                        return v;
                    })
                })
            } else {
                setValues(prev => {
                    return prev.map((v) => {
                        if (v.label === label){
                            v.value = value;
                        }
                        return v;
                    })
                })
            }
        }
    
        return (
            <div style={{display: "flex", width: "100%"}}>
                <div className="multi-inputs" style={{width: "100%"}}>
                    <table>
                        <tbody>
                        { values.map(({label, value}, idx) => {
                            return (
                                <tr key={idx} style={{width: "100%"}}>
                                    <td>{label}</td>
                                    <td style={{width: "100%", paddingLeft: ".5em"}}>
                                        <NumberInput
                                            className="w-100"
                                            value={value}
                                            min={this.min}
                                            max={this.max}
                                            step={this.step}
                                            onInput={e => updateValues(e.currentTarget.valueAsNumber, label, linked)}
                                        />
                                    </td>
                                </tr>
                                )
                        }) }
                        </tbody>
                    </table>
                </div>
                <div className="d-flex align-items-center">
                    <FontAwesomeIcon
                        icon={faLink}
                        className="icon-btn p-2"
                        style={{
                            opacity: linked ? 1 : 0.5
                        }}
                        onClick={_ => setLinked(!linked)}
                    />
                </div>
            </div>
        );
    }
}

import React, { useEffect, useState } from "react";

import { useUpdateEffect } from "usehooks-ts";

import ClipPropertyChangeCommand from "@services/commands/clip_property_change";
import ClipProperty from "@models/clipproperty";
import NumberInput from "@components/inputs/number";
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';


export default class DualRange extends ClipProperty {
    public title: string = "DualRange";
    public min: number = 0;
    public max: number = 100;
    public step: number = 1;
    public values: {left: number, right: number} = {left: 20, right: 80};

    public exportPayload() {
        return {
            ...super.exportPayload(),
            values: this.values
        };
    }


    public set(values: {left: number, right: number}) {
        let cmd = new ClipPropertyChangeCommand(this.parent, this, values);
        cmd.setGrouping(true);
        cmd.execute();
    }

    public getPropertyEditorComponent() {
        return (
            <this.PropertyEditorWrapperComponent property={this}>
                <this.PropertyEditor />    
            </this.PropertyEditorWrapperComponent>
        );
    }
    
    protected PropertyEditor = () => {
        const [values, setValues] = useState<{left: number, right: number}>(this.values);

        useEffect(() => {
            window.projectPageForceRerender();
        }, [])
        
        useUpdateEffect(() => {
            this.max = Math.round(this.max);
            this.set(values);
        }, [ values ]);

        return (<>
            <div className="dualrange-wrapper">
                <RangeSlider
                    min={this.min}
                    max={Math.round(this.max)}
                    step={this.step}
                    value={[this.values.left,this.max - this.values.right]}
                    onInput={(e) => {
                        setValues({left: e[0], right: Math.round(this.max - e[1])});
                        this.values = {left: e[0], right: Math.round(this.max - e[1])}
                    }}
                />
                <div className="dualrange-number">
                    <NumberInput
                        min={0}
                        max={Math.round(this.max - this.values.right)}
                        value={Math.round(this.values.left)}
                        step={this.step}
                        onInput={e =>{
                            this.values.left = e.currentTarget.valueAsNumber;
                            setValues({left: this.values.left, right: this.values.right})
                        }
                        }
                    />
                    <NumberInput
                        min={0}
                        max={this.max}
                        step={this.step}
                        value={Math.round(this.values.right)}
                        onInput={e =>{
                            this.values.right = e.currentTarget.valueAsNumber;
                            setValues({left: this.values.left, right: this.values.right})
                        }
                        }
                    />
                </div>
            </div>
        </>)
    }
}


import React from "react";
import $ from "jquery";

import AnchorPosition from "@enums/anchorposition";
import OnChangeDecorator, { OnChange } from "@decorators/onchange";
import { Set } from "@decorators/set";

import Color from "@clip/properties/color";
import Anchor from "@clip/properties/anchor";
import Range from "@clip/properties/range";
import ClipEffect from "@models/clipeffect";

import TargetLineEffect from "./targetline";
import { getSubtitleManager } from "@providers/subtitlemanager";
import NumberProperty from "@clip/properties/number";
import DualRange from "@clip/properties/dualrange";
import Clip from "@components/timeline/clip";
import { ThemeProvider } from "react-bootstrap";
import BorderEffect from "./border";


export default class TitleEffect extends ClipEffect {
    public type = "TitleEffect";

    public SHOW_DURATION = 0;
    public HIDE_DURATION = 0;

    @Set({ i18nKey: "pages.project.property_name.background_color" })
    @OnChange((effect: TitleEffect) => {
        effect.elements.mainBg.css("background-color", effect.backgroundColor.value);
    })
    public backgroundColor: Color = new Color(this);

    @Set({ i18nKey: "pages.project.property_name.border_color" })
    @OnChange((effect: TitleEffect) => {
        effect.elements.border.css("background-color", effect.borderColor.value);
    })
    public borderColor: Color = new Color(this);

    @Set({ i18nKey: "pages.project.property_name.padding", min: 0.3, max: 2, step: 0.1 })
    @OnChange((effect: TitleEffect) => {
        effect.create();
    })
    public padding: Range = new Range(this);

    @Set({ i18nKey: "pages.project.property_name.animation_speed" })
    @OnChange((effect: TitleEffect) => {
        effect.create();
    })
    public timelineSpeed: DualRange = new DualRange(this);

    @OnChange((effect: TitleEffect) => {
        effect.create();

        let targetLineEffect = effect.clip.getEffect<TargetLineEffect>("TargetLineEffect");
        targetLineEffect?.create?.();
    })
    public anchor: Anchor = new Anchor(this);


    constructor() {
        super();

        this.backgroundColor.value = "#ED7238";
        this.borderColor.value     = "#FFDCC4";
        this.padding.value         = 0.3;
        this.anchor.value          = AnchorPosition.LeftCenter;
    }

    public init() {
        super.init();

        this.timelineSpeed.max    = this.clip.duration.value;
        this.timelineSpeed.values = { left: this.clip.duration.value * 0.1, right: this.clip.duration.value * 0.1 };

        OnChangeDecorator.subscribe(this.clip, "duration", () => {
            this.timelineSpeed.max = this.clip.duration.value;
            if (this.timelineSpeed.max - this.timelineSpeed.values.right < this.timelineSpeed.values.left){
                this.timelineSpeed.values.right = this.timelineSpeed.max - this.timelineSpeed.values.right;
            }
        });
    }

    public exportPayload() {
        let payload = super.exportPayload();
        payload.data.backgroundColor = this.backgroundColor.exportPayload();
        payload.data.borderColor     = this.borderColor.exportPayload();
        payload.data.padding         = this.padding.exportPayload();
        payload.data.anchor          = this.anchor.exportPayload();
        payload.data.timelineSpeed   = this.timelineSpeed.exportPayload();

        return payload;
    }


    public getPropertyEditorComponents() {
        return (
            <this.PropertyEditorWrapperComponent property={this}>
                { this.backgroundColor.getPropertyEditorComponent() }
                { this.borderColor.getPropertyEditorComponent() }
                { this.padding.getPropertyEditorComponent() }
                { this.timelineSpeed.getPropertyEditorComponent() }
                { this.anchor.getPropertyEditorComponent("horizontal", false) }
            </this.PropertyEditorWrapperComponent>
        );
    }


    public createElements(subtitleElement: JQuery<HTMLElement>) {
        const [ subtitleManager ] = getSubtitleManager();
        const wrapper = subtitleElement.find(".subtitle-wrapper");
        const content = subtitleElement.find(".subtitle-content");

        const isLeftSided = this.anchor.value === AnchorPosition.LeftCenter;

        this.elements.mainBg = $("<div/>")
            .css({
                position: "absolute",
                left: isLeftSided ? 0 : "auto",
                right: isLeftSided ? "auto" : 0,
                top: 0,
                width: "0%",
                height: "100%",
                overflow: "hidden",
                borderRadius: (this.clip.hasEffect("BorderEffect")) ? (this.clip.getEffect("BorderEffect") as BorderEffect).borderRadius.value : 0,
                backgroundColor: this.backgroundColor.value,
            })
            .addClass("mainbg")
            .prependTo(wrapper);

        this.elements.border = $("<div/>")
            .css({
                position: "absolute",
                left: isLeftSided ? 0 : "auto",
                right: isLeftSided ? "auto" : 0,
                top: 0,
                width: "100%",
                height: "100%",
                backgroundColor: this.borderColor.value,
            })
            .prependTo(this.elements.mainBg);

        let borderWidth = wrapper.width() * 0.02 + "px";
        let padding     = this.padding.value * 16 * subtitleManager.getSubtitleScaleRatio();

        content.css({
            paddingTop:    `${padding}px`,
            paddingBottom: `${padding}px`,
            paddingLeft:   isLeftSided ? `calc(${borderWidth} + 6px + ${padding}px)` : `calc(6px + ${padding}px)`,
            paddingRight:  isLeftSided ? `calc(6px + ${padding}px)`                  : `calc(${borderWidth} + 6px + ${padding}px)`
        });
    }

    public createAnimations(subtitleElement: JQuery<HTMLElement>) {
        const content = subtitleElement.find(".subtitle-content");

        const { mainBg, border } = this.elements;

        let show_duration = this.timelineSpeed.values.left
        let hide_duration = this.timelineSpeed.values.right
        let hide_delay = this.clip.duration.value - hide_duration - show_duration;


        if (show_duration === 0){
            mainBg.css({
                width: "100%",
                opacity: "1"
            });
            content.css({
                opacity: "1"
            });
            border.css({
                width: "2%"
            });

        } else {
            // defaults
            this.timeline
            .add({
                targets:  mainBg.get(0),
                duration: 1,
                width:    "0%",
                opacity:  0
            }, "-=1")
            .add({
                targets:  content.get(0),
                duration: 1,
                opacity:  0
            }, "-=1")
            .add({
                targets:  border.get(0),
                duration: 1,
                width:    "0%"
            }, "-=1");

            // show

            this.timeline
                .add({
                    targets:  mainBg.get(0),
                    easing:   "easeOutQuad",
                    duration: 3/4 * show_duration,
                    width:    "100%",
                    opacity:  1
                })
                .add({
                    targets:  content.get(0),
                    duration: 1/4 * show_duration,
                    opacity:  1
                })
                .add({
                    targets:  border.get(0),
                    duration: 1/5 * show_duration,
                    width:    "2%"
                }, `-=${1/4 * show_duration}`);
        }
        this.SHOW_DURATION = show_duration;

        // hide
        if (hide_duration !== 0){
            this.timeline
                .add({
                    targets:  content.get(0),
                    delay:    hide_delay,
                    duration: 1/3 * hide_duration,
                    opacity:  0
                })
                .add({
                    targets:  border.get(0),
                    duration: 2/3 * hide_duration,
                    width:    "100%"
                })
                .add({
                    targets:    mainBg.get(0),
                    easing:     "easeOutQuad",
                    duration:   1/3 * hide_duration,
                    width:      "25%",
                    translateX: "75%",
                    opacity:    0
                }, `-=${1/3 * hide_duration}`);
        }

        this.HIDE_DURATION = hide_duration;

        if (this.clip.hasEffect("TargetLineEffect")){
            this.clip.getEffect("TargetLineEffect").create();
        }
    }
}

import React, { ReactNode } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";

type ModalProps = {
    title?: string;
    text?: string;
    show?: boolean;
    hide: Function;
    showCloseButton?: boolean;
    confirmText?: string;
    cancelText?: string;
    imageSrc?: string;
    component?: ReactNode;
    callback: (data: {
        accepted: boolean;
    }) => void;
};

const ConfirmModal = ({
    title = "Confirm",
    text = "",
    show = true,
    hide,
    showCloseButton = true,
    confirmText = "Confirm",
    cancelText = "Cancel",
    imageSrc,
    component,
    callback
}: ModalProps) => {
    const confirm = async (accepted: boolean) => {
        await callback({ accepted });
        hide();
    };

    return (
        <Modal show={show} centered onHide={() => showCloseButton && hide()}>
            <Modal.Header closeButton={showCloseButton} onClick={() => showCloseButton && hide()}>
                <Modal.Title>
                    { title }
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Row>
                    <Col xs={ imageSrc ? 9 : 12 } dangerouslySetInnerHTML={{ __html: text }} />

                    {imageSrc && (
                        <Col xs="3" className="d-flex align-items-center justify-content-center">
                            <img src={imageSrc} className="w-100" />
                        </Col>
                    )}
                </Row>

                {component && (
                    <Row className="mt-2">
                        <Col>
                            { component }
                        </Col>
                    </Row>
                )}
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={() => confirm(true)}>
                    { confirmText }
                </Button>

                <Button variant="secondary" onClick={() => confirm(false)}>
                    { cancelText }
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ConfirmModal;

// https://github.com/daniel-araujo/deno-rsync-parser/blob/master/rsync_itemize_changes_parser.ts

// Rsync operation types
const RSYNC_TYPE_SENT           = "<"; // A file is being transferred to the remote host (sent).
const RSYNC_TYPE_RECEIVED       = ">"; // A file is being transferred to the local host (received).
const RSYNC_TYPE_CHANGED        = "c"; // A local change/creation is occurring for the item (such as the creation of a directory or the changing of a symlink, etc.).
const RSYNC_TYPE_INFO_HARD_LINK = "h"; // The item is a hard link to another item (requires --hard-links).
const RSYNC_TYPE_MESSAGE        = "*"; // The rest of the itemized-output area contains a message (e.g. "deleting").
const RSYNC_TYPE_UNCHANGED      = ".";

// Rsync file types
const RSYNC_FILE_FILE      = "f"; // File.
const RSYNC_FILE_DIRECTORY = "d"; // Directory.
const RSYNC_FILE_SYMLINK   = "L"; // Symbolic link.
const RSYNC_FILE_DEVICE    = "D"; // Device.
const RSYNC_FILE_SPECIAL   = "S"; // Special file.

type FileType = "file" | "directory" | "symlink" | "device" | "special";

// Converts rsync file type to our file type.
function rsyncFileTypeToOurFileType(rsyncFileType: string): FileType {
    switch (rsyncFileType) {
        case RSYNC_FILE_FILE:
            return "file";
        case RSYNC_FILE_DIRECTORY:
            return "directory";
        case RSYNC_FILE_SYMLINK:
            return "symlink";
        case RSYNC_FILE_DEVICE:
            return "device";
        case RSYNC_FILE_SPECIAL:
            return "special";
        default:
            throw new Error("Type not recognized.");
    }
}

export type RsyncItemChanges = ItemCreate | ItemUpdate | ItemDelete | ItemCannotDelete | ItemUnchanged;

interface ItemCreate {
    type: "create";
    local: boolean;
    sent: boolean;
    received: boolean;
    hardlink: boolean;
    hardlinkPath: string | null;
    path: string;
    fileType: FileType;
}

interface ItemUpdate {
    type: "update";
    sent: boolean;
    received: boolean;
    hardlink: boolean;
    hardlinkPath: string | null;
    checksum: boolean;
    size: boolean;
    timestamp: boolean;
    permissions: boolean;
    owner: boolean;
    group: boolean;
    acl: boolean;
    xattr: boolean;
    path: string;
    fileType: FileType;
}

interface ItemDelete {
    type: "delete";
    path: string;
}

interface ItemCannotDelete {
    type: "cannotDelete";
    path: string;
    fileType: FileType;
}

interface ItemUnchanged {
    type: "unchanged";
    path: string;
    fileType: FileType;
}

// Parses output from rsync command ran with the --itemize-changes option.
const parseItemizeChanges = (output: string[]): RsyncItemChanges[] => {
    let result: RsyncItemChanges[] = [];

    for (let line of output) {
        // Y is replaced by the type of update being done.
        let Y = line[0];

        if (line.startsWith("cannot delete non-empty directory:")) {
            // This does not follow the general format but it shows up in the output.

            // Path shows up after the message.
            let path = line.substring(35);

            result.push({
                type: "cannotDelete",
                path: path,
                fileType: "directory",
            });
            continue;

        } else if (
            Y == RSYNC_TYPE_SENT || Y === RSYNC_TYPE_RECEIVED ||
            Y === RSYNC_TYPE_INFO_HARD_LINK ||
            Y === RSYNC_TYPE_UNCHANGED ||
            Y === RSYNC_TYPE_CHANGED
        ) {
            let X = line[1]; // File type.

            // Checksum for regular files, a change in some value for symlinks,
            // devices and special files. If it's a plus sign then it means that a file was created.
            let c = line[2];

            // Path is separated by a space from the codes.
            let path = line.substring("YXcstpoguax".length + " ".length);

            let hardlink = Y === RSYNC_TYPE_INFO_HARD_LINK;
            let hardlinkPath = (() => {
                if (Y === RSYNC_TYPE_INFO_HARD_LINK) {
                    // The path may also contain information about the hard link.
                    let hardLinkSeparatorIndex = path.indexOf(" => ");
                    
                    if (hardLinkSeparatorIndex !== -1) {
                        // It is present. We can retrieve it.
                        let hardlinkPath = path.substring(
                            hardLinkSeparatorIndex + " => ".length,
                        );

                        // The hard link information needs to be removed from path.
                        path = path.substring(0, hardLinkSeparatorIndex);

                        return hardlinkPath;
                    }
                }

                return null;
            })();

            if (c === " ") {
                result.push({
                    type: "unchanged",
                    path: path,
                    fileType: rsyncFileTypeToOurFileType(X),
                });
                continue;

            } else if (c === "+") {
                result.push({
                    type: "create",
                    local: Y == RSYNC_TYPE_CHANGED,
                    sent: Y == RSYNC_TYPE_SENT,
                    received: Y === RSYNC_TYPE_RECEIVED,
                    hardlink: hardlink,
                    hardlinkPath: hardlinkPath,
                    path: path,
                    fileType: rsyncFileTypeToOurFileType(X),
                });
                continue;

            } else {
                let c = line[2];  // Different checksum
                let s = line[3];  // Size change.
                let t = line[4];  // Timestamp change.
                let p = line[5];  // Permissions change.
                let o = line[6];  // Owner change.
                let g = line[7];  // Group change.
                let a = line[9];  // ACL change.
                let x = line[10]; // Extended attributes changed.

                // This space separates the codes from the path.
                let expectedSpace = line[11];

                if (expectedSpace === " ") {
                    result.push({
                        type: "update",
                        sent: Y == RSYNC_TYPE_SENT,
                        received: Y === RSYNC_TYPE_RECEIVED,
                        hardlink: hardlink,
                        hardlinkPath: hardlinkPath,
                        checksum: c === "c",
                        size: s === "s",
                        timestamp: t === "t" || t === "T",
                        permissions: p === "p",
                        owner: o === "o",
                        group: g === "g",
                        acl: a === "a",
                        xattr: x === "x",
                        path: path,
                        fileType: rsyncFileTypeToOurFileType(X),
                    });
                    continue;
                }
            }

        } else if (Y === RSYNC_TYPE_MESSAGE) {
            // Does not follow general format.
            let deletingMessage = line.substring(1, 9);

            if (deletingMessage === "deleting") {
                let path = line.substring(12);

                result.push({
                    type: "delete",
                    path: path,
                });
                continue;
            }
        }
    }

    return result;
}


export { parseItemizeChanges };

import { getVideoPlayer } from "@providers/videoplayer";

import Project, { ChannelBackendPayload } from "./project";

import Clip from "./clip/clip";
import TextClip from "./clip/textclip";
import ImageClip from "./clip/imageclip";
import ShapeClip from "./clip/shapeclip";
import ActionClip from "./clip/actionclip";
import MarkerClip from "./clip/markerclip";

export enum ChannelType {
    NORMAL = "normal",
    MARKER = "marker"
}

export default class Channel {
    public project: Project;
    public id: number = 0;
    public clips: Clip[] = [];
    public locked: boolean = false;
    public hidden: boolean = false;
    public selected: boolean = false;
    public type: ChannelType = ChannelType.NORMAL;


    constructor(project: Project) {
        this.project = project;
    }

    public exportPayload() {
        return {
            id:              this.id,
            clips:           this.clips.map(v => v.exportPayload()),
            locked:          this.locked,
            hidden:          this.hidden,
            type:            this.type
        };
    }

    public applyPayload(data: ChannelBackendPayload) {
        this.id     = data.id;
        this.locked = data.locked;
        this.hidden = data.hidden;
        this.type   = data.type;

        data.clips.forEach(clip_values => {
            let clipClass = getClipClassFromType(clip_values.type);

            let clip = new clipClass(this);
            clip.init();
            clip.applyPayload(clip_values);

            this.clips.push(clip);
        });
    }

    
    public index(): number {
        return this.project.channels.indexOf(this);
    }

    public delete(): boolean {
        this.clips.forEach(clip => {
            clip.delete();
        });
        
        return this.project.removeChannel(this);
    }

    public addClip(clip: Clip): boolean {
        this.clips.push(clip);

        let player = getVideoPlayer();
        player.subtitleManager.clips = this.project.getClips();
        player.subtitleManager.createClipElements([ clip ]);

        return true;
    }

    public removeClip(clip: Clip): boolean {
        let element = clip.findSubtitleElement();
        this.project.setSelectedClip(null);
        element.remove();

        this.clips = this.clips.filter(v => v !== clip);
        
        let player = getVideoPlayer();
        player.subtitleManager.clips = this.project.getClips();
        
        return true;
    }

    public createTextClip(): TextClip {
        const clip = new TextClip(this);
        clip.id    = this.project.nextTempID();

        // init
        clip.init();
        
        // default values
        clip.position.set(35, 42.5);

        this.addClip(clip);

        return clip;
    }

    public createImageClip(path: string): ImageClip {
        const clip = new ImageClip(this);
        clip.id    = this.project.nextTempID();
        clip.path  = path;

        // init
        clip.init();
        
        // default values
        clip.position.set(35, 30);

        this.addClip(clip);

        return clip;
    }

    public createShapeClip(): ShapeClip {
        const clip = new ShapeClip(this);
        clip.id    = this.project.nextTempID();

        // init
        clip.init();
        
        // default values
        clip.position.set(35, 30);

        this.addClip(clip);

        return clip;
    }

    public createActionClip(): ActionClip {
        const clip = new ActionClip(this);
        clip.id    = this.project.nextTempID();

        // init
        clip.init();

        this.addClip(clip);

        return clip;
    }

    public createTimelineMarkerClip(): MarkerClip {
        const clip = new MarkerClip(this);
        clip.id    = this.project.nextTempID(),

        // init
        clip.init();

        this.addClip(clip);

        return clip;
    }
}

export const getClipClassFromType = (type: string) => {
    switch (type) {
        case "TextClip":   return TextClip;
        case "ImageClip":  return ImageClip;
        case "ShapeClip":  return ShapeClip;
        case "MarkerClip": return MarkerClip;
        case "ActionClip": return ActionClip;
    }
}

import React, { useEffect } from "react";
import { useEventListener } from "usehooks-ts";
import $ from "jquery";


const PreventKeyboardPageScrolls = () => {
    useEventListener("keydown", (e: any) => {
        if (
            e.keyCode == 32 &&
            (
                ! ["TEXTAREA", "INPUT"].includes(e.target.nodeName) && // nem inputban van
                ! $(e.target).attr("contenteditable") && // nem contenteditable-ban van
                $(e.target).closest("div[contenteditable]").length == 0 // nincs contenteditable parentje
            )
        ) {
            e.preventDefault();
        }

        if (document.activeElement.tagName !== "INPUT" && ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].includes(e.code)) {
            e.preventDefault();
        }
    })
    
    // prevent ctrl+wheel browser page zoom
    // https://stackoverflow.com/a/68236287/15541174
    useEffect(() => {
        if (window === top) {
            const preventScroll = (e) => {
                if (e.ctrlKey && !e.shiftKey) {
                    e.preventDefault();
                    return false;
                }
            }

            window.addEventListener("wheel", preventScroll, { passive: false });

            return () => {
                window.removeEventListener("wheel", preventScroll);
            };
        }
    }, [])

    return <></>;
};

export default PreventKeyboardPageScrolls;

import React, { useEffect, useState, useRef } from "react";
import Pickr from "@simonwep/pickr";

import { createColorPicker } from "@utils/color";
import ClipPropertyChangeCommand from "@services/commands/clip_property_change";
import ClipProperty from "@models/clipproperty";
import ToastService from "@services/ToastService";
import { CSSProperties } from "@utils/styles";



export default class Color extends ClipProperty {
    public i18nKey: string = "pages.project.property_name.color";

    public value: string = "#ffffff";
    public adjustOpacity : boolean = true;
    public swatches: string[];
    public onlySwatches: boolean = false;

    private pickr: Pickr;

    public exportPayload() {
        return {
            ...super.exportPayload(),
            value: this.value
        };
    }

    public set(value: string) {
        let cmd = new ClipPropertyChangeCommand(this.parent, this, { value });
        cmd.setGrouping(true);
        cmd.execute();
    }

    public toCSS(): CSSProperties {
        return {
            color: this.value
        };
    }

    public getPropertyEditorComponent() {
        return (
            <this.PropertyEditorWrapperComponent property={this} inline={!this.onlySwatches}>
                <this.PropertyEditor />
            </this.PropertyEditorWrapperComponent>
        );
    }

    protected PropertyEditor = () => {
        const ref = useRef<HTMLDivElement>();
        const [value, setValue] = useState(this.value);

        const handleRightClick = (e) => {
            e.preventDefault();

            if (!window.EyeDropper) {
                ToastService.error("Your browser does not support this feature!");
                return;
            }

            this.pickr.hide();

            const eyeDropper = new window.EyeDropper();

            eyeDropper.open()
                .then((result) => {
                    setValue(result.sRGBHex);
                    this.pickr.setColor(result.sRGBHex);
                })
                .catch((e) => {
                    console.log(e);
                });
        };
        
        useEffect(() => {
            this.set(value);
        }, [ value ]);

        useEffect(() => {
            if (! ref.current)
                return;

            this.pickr = createColorPicker(
                ref.current,
                color => setValue(color),
                value,
                {
                    lockOpacity: ! this.adjustOpacity,
                    swatches: this.swatches
                } as Pickr.Options
            );

            return () => {
                this.pickr.destroyAndRemove();
            }
        }, [ ref ]);

        return (<>
            {!this.onlySwatches && 
                <div onContextMenu={(e) =>  handleRightClick(e)}>
                    <div ref={ref}/>
                </div>}
            {this.onlySwatches && 
                <div className="swatches_holder" style={{display: "flex", flexWrap: "wrap", gap: ".3em"}}>
                    {this.swatches.map((swatch, idx) => {
                        return (<div 
                            key={idx}
                            style={{
                                width: "calc( ( 100% - ( 5 * .3em ) ) / 6 )",
                                aspectRatio: "1",
                                height: "auto",
                                backgroundColor: swatch,
                                borderRadius: "5px",
                                cursor: "pointer"
                            }}
                            onClick={_ => {setValue(swatch)}}
                        ></div>)
                    })}
                </div>}
            </>
        );
    }
}

import React, { createContext, ReactElement, useContext, useEffect, useRef, useState } from "react";
import { useEventListener } from "usehooks-ts";
import $ from "jquery";
import classNames from "classnames";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { arrayFindClosest } from "@utils/array";
import { useProjectPageContext } from "@pages/project/project";

import ChannelModel from "@models/channel";
import VideoPlayer from "@models/videoplayer";
import { useProject } from "@providers/project";
import KeyService from "@services/KeyService";
import SubtitleEditorService from "@services/SubtitleEditorService";
import Channel from "./channel";
import ChannelControls from "./channelcontrols";
import Ruler from "@components/guide/ruler";

type TimelineProps = {
    player: VideoPlayer;
    className?: string;
    disabled?: boolean,
}

const Context = createContext(null);

const Timeline = ({ player, className, disabled }: TimelineProps) => {
    const [project] = useProject();
    const timelineRef = useRef<HTMLDivElement>();
    const scrollContainer = useRef<HTMLDivElement>();
    const timestampRef = useRef<HTMLDivElement>();
    const controlsRef = useRef<HTMLDivElement>();
    const caretRef = useRef<HTMLDivElement>();
    const markersRef = useRef<HTMLDivElement>();
    const [zoom, setZoom] = useState(100);
    const [snapTolerance, setSnapTolerance] = useState(750);
    const { moveCaretToMS } = useProjectPageContext();
    
    const snapDistancePx = 10;
    let snapPoints: number[] = [];
    
    const rulerStyle = {
        mainTick: {
            length: 0.5,
            width: 2,
            color: {
                activeColor: "#898989",
                inactiveColor: "#454545"
            }
        },
        evenTick: {
            length: 0.2,
            width: 2,
            color: {
                activeColor: "#454545",
                inactiveColor: "#454545"
            }
        },
        oddTick: {
            length: 0.3,
            width: 2,
            color: {
                activeColor: "#5c5c5c",
                inactiveColor: "#454545"
            }
        }
    }

    const rulerRef = useRef(null);
    const rulerContainerRef = useRef(null);

    const updateRuler = () => {
        const timelineWidth = timestampRef.current.getBoundingClientRect().width || 0;
        const duration      = player.duration();
        
        const minSegmentWidth = 75;
        const maxSegments     = Math.floor(timelineWidth / minSegmentWidth);
        const step            = Math.ceil((duration / maxSegments) / 500) * 500;
        
        const segmentWidth    = timelineWidth / (duration / step);

        rulerRef.current.changeProperty("segmentWidth", segmentWidth);
        rulerRef.current.changeProperty("markings", step);

        rulerRef.current.changeProperty("onlySegments", {from: 0})
        
        rulerRef.current.changeProperty("width", rulerContainerRef.current.getBoundingClientRect().width);
        rulerRef.current.changeProperty("font", "11px Roboto");
        rulerRef.current.changeProperty("offset", timestampRef.current.getBoundingClientRect().left - rulerContainerRef.current.getBoundingClientRect().left - 1);

        setSnapTolerance( Math.round(duration * (snapDistancePx / timelineWidth)) );
    }

    const onTimestampMouseDown = (e: any) => {
        if (e.button !== 0)
            return;
        
        snapPoints = player.getTimelineSnapPointsMS(false, null, snapTolerance);
            
        let doc = $(document);

        doc.on("mousemove", updateCaretPositionByMousePoisition);

        doc.one("mouseup", e => {
            doc.off("mousemove", updateCaretPositionByMousePoisition);
        });

        updateCaretPositionByMousePoisition(e);
    }

    const updateCaretPositionByMousePoisition = (e: any) => {
        let channelRect = timestampRef.current.getBoundingClientRect();
        let offset      = Math.max(0, Math.min(channelRect.width, e.pageX - channelRect.left));
        let ms          = player.duration() * (offset / channelRect.width);
        
        if (! KeyService.isPressing("ShiftLeft")) {
            ms = arrayFindClosest(snapPoints, ms, snapTolerance) || ms;
        }

        player.setCurrentTime(ms);
        moveCaretToMS(ms);

        SubtitleEditorService.setTarget(null);
    }

    useEventListener("wheel", (e: WheelEvent) => {
        e.preventDefault();
        e.stopPropagation();

        let scrollContainerElem = $(scrollContainer.current);
        let scrollContentElem   = scrollContainerElem.children().first();

        if (KeyService.isPressing("ControlLeft")) {
            const direction = e.deltaY > 0 ? -1 : 1;
            const step      = Math.max(50, 100 + (-0.05 * (zoom - 100)));
            const newValue  = Math.max(100, zoom + (step * direction));

            const oldWidth  = scrollContentElem.width();

            scrollContentElem.css("width", `${newValue}%`);
            setZoom(newValue);

            const newWidth  = scrollContentElem.width();
            const diffWidth = newWidth - oldWidth;
            
            const caretPositionPercentage = player.currentTime() / player.duration();
            const scrollBoxMoveOffset     = diffWidth * caretPositionPercentage;

            scrollContainer.current.scrollLeft += scrollBoxMoveOffset;

            updateRuler();

        } else {
            if (KeyService.isPressing("ShiftLeft")) {
                scrollContainer.current.scrollTop += e.deltaY / 2;
            
            } else {
                scrollContainer.current.scrollLeft += e.deltaY;
            }
        }
    }, timelineRef);

    useEffect(() => {
        updateRuler();
        moveCaretToMS(player.currentTime());
    });

    const contextValue = {
        zoomPercentage: 100 / zoom,
        snapTolerance
    };

    return (
        <Context.Provider value={contextValue}>
            <div
                ref={timelineRef}
                className={classNames("timeline", className)}
            >
                <div
                    ref={scrollContainer}
                    className="scroll-container"
                    onScroll={e => {
                        rulerContainerRef.current.style.top = `${e.currentTarget.scrollTop}px`;
                        caretRef.current.style.top     = `${e.currentTarget.scrollTop}px`;
                        controlsRef.current.style.left = `${e.currentTarget.scrollLeft}px`;
                        markersRef.current.style.top   = `${e.currentTarget.scrollTop}px`;
                    }}
                >
                    <div className="d-flex position-relative overflow-hidden" style={{ width: `${zoom}%` }}>
                        <div ref={controlsRef} className="channel-controls-group">
                            {project.channels.slice(1).map((channel: ChannelModel, i: number) => (
                                <ChannelControls
                                    key={i}
                                    channel={channel}
                                />
                            ))}
                        </div>

                        <div 
                            ref={rulerContainerRef}
                            style={{
                                position: "absolute",
                                zIndex: 1,
                                width: "100%"
                            }}
                            className="timestamp-line"
                            
                        >
                            <Ruler
                                ref={rulerRef}
                                markings={100}
                                numberOfTicks={4}
                                segmentWidth={70}
                                height={32}
                                fontColor={{activeColor: "#ababab", inactiveColor: "#ababab"}}
                                width={1000}
                                labelFn={player.getTimeString}
                                style={{
                                    pointerEvents: "none",
                                    width: "100%"
                                }}
                                
                                textAlign="center"
                                tickDesigns={rulerStyle}
                                orientation="horizontal"
                            />
                        </div>

                        <div className="timeline-content">
                            {<div
                                ref={timestampRef}
                                style={{opacity: 0}}
                                className="timestamp-line"
                                onMouseDown={onTimestampMouseDown}
                            />}
                            

                            <div ref={markersRef} className="markers-line" style={{zIndex: 2000, width: "100%"}}>
                                <Channel channel={project.channels[0]} channel_index={0} />
                            </div>

                            <div className={classNames("channels-group", { "area-disabled": disabled })}>
                                {project.channels.slice(1).map((channel: ChannelModel, i: number) => (
                                    <Channel
                                        key={i+1}
                                        channel={channel}
                                        channel_index={i+1}
                                    />
                                ))}
                            </div>

                            <div ref={caretRef} id="timeline_caret" className="caret">
                                <FontAwesomeIcon icon={faCaretDown} className="marker" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Context.Provider>
    );
};

export default Timeline;

export const useTimelineContext = () => useContext(Context);

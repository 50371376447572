import "reflect-metadata";

const MetadataKey = Symbol("OnChangeDecorator");

interface IEventSubscriber {
    origin: any;
    propertiesFilter: any[];
    handler: Function;
};

let eventSubscribers: IEventSubscriber[] = [];


export const OnChange = (callback: Function) => {
    return function(target: any, propertyKey: string) {
        let properties: any = Reflect.getMetadata(MetadataKey, target) ?? {};

        properties[propertyKey] = callback;

        Reflect.defineMetadata(MetadataKey, properties, target);
    }
}

const callOnProperty = (origin: any, property: any) => {
    const properties: any = Reflect.getMetadata(MetadataKey, origin) ?? {};

    for (let propertyKey in properties) {
        if (origin[propertyKey] === property) {
            properties[propertyKey](origin, property);
        }
    }

    eventSubscribers.forEach(v => {
        if (v.origin === origin && (v.propertiesFilter.length === 0 || v.propertiesFilter.includes(property))) {
            v.handler();
        }
    });

    return null;
}

const subscribe = (origin: any, properties: string | string[] | null, handler: Function) => {
    let propertiesFilter = [];

    if (properties) {
        if (Array.isArray(properties)) {
            propertiesFilter = properties.map(v => origin[v]);

        } else {
            propertiesFilter = [ origin[properties] ];
        }
    }

    eventSubscribers.push({
        origin,
        propertiesFilter,
        handler
    });
}

const unsubscribe = (origin: any) => {
    eventSubscribers = eventSubscribers.filter(v => v.origin !== origin);
}

const OnChangeDecorator = { callOnProperty, subscribe, unsubscribe };

export default OnChangeDecorator;

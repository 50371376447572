import Moveable from "moveable";

import { getVideoPlayer } from "@providers/videoplayer";
import Clip from "@models/clip/clip";
import SubtitleEditCommand from "./commands/subtitle_edit";
import { getProject } from "@providers/project";
import { Direction, Guide } from "@components/guide/guidewrapper";

let moveable: Moveable;
let targetClip: any;

const updateClipProperties = ({ left, top, width, height, rotation, drag }: any) => {
    if (! targetClip)
        return false;

    let player = getVideoPlayer();

    let cmd    = new SubtitleEditCommand(targetClip, {});
    let values = cmd.getPreviousValues();

    if (typeof left === "number" && typeof top === "number") {
        let [x, y] = player.calcRelativeDimensions(left, top);

        values.positionX = x;
        values.positionY = y;

    } else if (drag?.transform) {
        let [x, y] = player.calcRelativeDimensions(drag.left, drag.top);

        values.positionX = x;
        values.positionY = y;
    }

    if (typeof width === "number" && typeof height === "number") {
        let [x, y] = player.calcRelativeDimensions(width, height);

        values.scaleX = x;
        values.scaleY = y;
    }

    if (typeof rotation === "number") {
        values.rotation = rotation;
    }

    cmd.values = values;
    cmd.setGrouping(true);
    cmd.execute();
}

const create = (parent: HTMLElement) => {
    moveable = new Moveable(parent, {
        draggable: true,
        throttleDrag: 0.1,

        resizable: true,
        throttleResize: 0.1,
        keepRatio: false,

        rotatable: true,
        throttleRotate: 1,
        snapRotationDegrees: [0, 90, 180, 270],

        snappable: true,
        snapDirections: {
            top:    true,
            left:   true,
            bottom: true,
            right:  true,
            center: true,
            middle: true
        },
        elementSnapDirections: {
            middle: true,
            center: true,
            top:    true,
            left:   true,
            bottom: true,
            right:  true
        },
        snapThreshold: 5,
        elementGuidelines: [],
        verticalGuidelines: [],
        horizontalGuidelines: [],

        origin: false
    });

    updateGuides()

    moveable.on("drag", updateClipProperties);
    moveable.on("resize", updateClipProperties);
    moveable.on("rotate", updateClipProperties);
}

const setTarget = (clip?: Clip, elementGuidelines: HTMLElement[] = []) => {
    if (! moveable)
        return false;

    if (! clip || ! clip.visualClip) {
        moveable.target = null;
        targetClip      = null;
        return true;
    }

    let elem = clip.findSubtitleElement();

    if (elem.css("display") === "none")
        return false;

    moveable.target            = elem[0];
    moveable.elementGuidelines = elementGuidelines;
    targetClip                 = clip;

    moveable.keepRatio = !! targetClip?.scale?.ratio;

    moveable.updateRect();

    return true;
}

const setKeepRatio = (state: boolean) => {
    if (! moveable)
        return false;

    moveable.keepRatio = state;
    return true;
}

const updateRect = () => {
    if (! moveable)
        return false;

    return moveable.updateRect();
}

const updateGuides = (visible: boolean = true) => {
    if (!visible){
        if (moveable){
            moveable.verticalGuidelines = [];
            moveable.horizontalGuidelines = [];
            return;
        }
    }

    let project = getProject();
    let player = getVideoPlayer();
    

    let horizontal : number[] = [];
    let vertical : number[] = [];



    if (player && project){
        project.guides.forEach((guide: Guide) => {
            if (guide.direction === Direction.HORIZONTAL){
                vertical.push(player.getDimensions()[0] / 100 * parseFloat(guide.position.split("%")[0]));
            } else if (guide.direction === Direction.VERTICAL){
                horizontal.push(player.getDimensions()[1] / 100 * parseFloat(guide.position.split("%")[0]));
            }
        })
    }

    if (vertical && horizontal && moveable){
        moveable.verticalGuidelines = vertical;
        moveable.horizontalGuidelines = horizontal;
    }
}


window.addEventListener("resize", _ => {
    if (! moveable)
        return;

    moveable.updateRect();
});


export default { create, setTarget, setKeepRatio, updateRect, updateGuides };

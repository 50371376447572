import React, { useEffect, useRef, useState } from "react";
import { useEventListener } from "usehooks-ts";

import Ruler from "./ruler";
import Project from "@models/project";
import SubtitleEditorService from "@services/SubtitleEditorService";
import KeyService from "@services/KeyService";
import { useProjectPageContext } from "@pages/project/project";
import { getVideoPlayer } from "@providers/videoplayer";
import VideoPlayer from "@models/videoplayer";
import Position from "@clip/properties/position";

type DraggingData = {
    lines: HTMLDivElement[];
    offsetX: number;
    offsetY: number;
    directions: Direction[];
}

type CursorPositionData = {
    relativePosition: {
        x: number;
        y: number;
    };
    menuPosition: {
        x: number;
        y: number;
    };
    direction: Direction;
};

type guideWrapperProperties = {
    project: Project;
    hidden?: boolean;
    children: any;
}

export enum Direction {
    VERTICAL   = "vertical",
    HORIZONTAL = "horizontal",
    COMBO      = "combo"
}

export type Guide = {
    position: string;
    direction: Direction;
}

let lineContainer = document.createElement("div");
lineContainer.classList.add("guide-line-container");

const GuideWrapper = ({ children, project, hidden }: guideWrapperProperties) => {
    const [movingEvent, setMovingEvent] = useState<DraggingData>();
    const [relativeCursorPosition, setRelativeCursorPosition] = useState<CursorPositionData>();
    const wrapRef = useRef<HTMLDivElement>();
    const [player, setPlayer] = useState<VideoPlayer>();
    
    const { videoDimensions } = useProjectPageContext();

    const { zoom } = useProjectPageContext();

    let snapToSegmentNum = 10;

    const onMoveHandler = (e: MouseEvent) => {
        let { pageX, pageY } = e;

        const childRect = children.ref.current.getBoundingClientRect();

        let linePositionX = pageX;
        let linePositionY = pageY;

        const distFromRectX = linePositionX - childRect.left;
        const distFromRectY = linePositionY - childRect.top;

        let snapDistX = childRect.width / videoDimensions.x * snapToSegmentNum;
        let snapDistY = childRect.height / videoDimensions.y * snapToSegmentNum;

        if (KeyService.isPressing("ControlLeft")){
            // SNAP X
            let offX = ((linePositionX - childRect.left) % snapDistX) - snapDistX / 2;
            if (offX < 0) offX += snapDistX;
            linePositionX = offX - snapDistX / 2;

            // SNAP Y
            let offY = ((linePositionY - childRect.top)  % snapDistY) - snapDistY / 2;
            if (offY < 0) offY += snapDistY;
            linePositionY = offY - snapDistY / 2;

            // "ROUNDING" TO RECT
            linePositionX = distFromRectX - linePositionX;
            linePositionY = distFromRectY - linePositionY;

        } else {
            linePositionX -= childRect.left;
            linePositionY -= childRect.top;
        }

        const { lines, directions } = movingEvent;

        setRelativeCursorPosition({
            relativePosition: {
                x: (linePositionX/childRect.width)*videoDimensions.x,
                y: (linePositionY/childRect.height)*videoDimensions.y
            },
            menuPosition: {
                x: pageX - wrapRef.current.getBoundingClientRect().left,
                y: pageY - wrapRef.current.getBoundingClientRect().top
            },
            direction: directions.length == 2
                ? Direction.COMBO
                : directions[0]
        });

        if (directions.length == 2){
            document.body.style.cursor = "all-scroll";

            movingEvent.lines.forEach(line => {
                line.style.cursor = "all-scroll";
            });

        } else {
            if (directions[0] === Direction.HORIZONTAL){
                document.body.style.cursor = "e-resize";
                lines[0].style.cursor = "e-resize";

            } else if (directions[0] === "vertical"){
                document.body.style.cursor = "s-resize";
                lines[0].style.cursor = "s-resize";
            }
        }

        for (let i = 0; i < lines.length; i++) {
            if (directions[i] === "horizontal"){
                lines[i].style.left = `calc(${(linePositionX/childRect.width)*100}% + 1px)`;
                lines[i].setAttributeNS("position","position",`${linePositionX}`)

            } else if (directions[i] === "vertical"){
                lines[i].style.top  = `calc(${(linePositionY/childRect.height)*100}% + 1px)`;
                lines[i].setAttributeNS("position","position",`${linePositionY}`)
            }
        }
    }

    const onMoveEnd = (e: any) => {
        document.body.style.cursor = "default";

        if (e.target.id === "ruler"){
            movingEvent.lines.forEach(line => {
                line.remove();
            });
        }

        SubtitleEditorService.updateGuides();
        setRelativeCursorPosition(null);

        movingEvent.lines.forEach(line => {
            let direction : Direction = line.classList[1] as Direction;

            line.style.cursor = (direction === Direction.HORIZONTAL) ? "e-resize" : "s-resize";

            let position = direction === Direction.VERTICAL
                ? line.style.top
                : line.style.left;

            project.guides.push({direction, position} as Guide);
        });

        updateProjectGuides();
        setMovingEvent(undefined);
    }

    const onMoveStart = (e: any, direction: Direction) => {
        const childRect = children.ref.current.getBoundingClientRect();

        let lines = [];

        let directions: Direction[] = direction === Direction.COMBO
            ? [ Direction.VERTICAL, Direction.HORIZONTAL ]
            : [ direction ];

        let offsetX = e.pageX - (e.pageX - childRect.left);
        let offsetY = e.pageY - (e.pageY - childRect.top);

        directions.forEach((direction) => {
            if (direction === Direction.VERTICAL) {
                lines.push(createGuide(direction, `${-offsetY}px`));

            } else if (direction === Direction.HORIZONTAL) {
                lines.push(createGuide(direction, `${-offsetX}px`));
            }
        });
        setMovingEvent({ lines: lines, offsetX, offsetY, directions: directions });
    }

    const onMouseDown = (e: any, direction: Direction) => {
        if (e.button !== 0)
            return;

        onMoveStart(e, direction);
    }

    useEffect(() => {
        if (movingEvent) {
            window.addEventListener("mousemove", onMoveHandler);
            window.addEventListener("mouseup", onMoveEnd);
        }

        return () => {
            window.removeEventListener("mousemove", onMoveHandler);
            window.removeEventListener("mouseup", onMoveEnd);
        };
    }, [ movingEvent ]);

    const moveExistingGuide = (e:any) => {
        const childRect = children.ref.current.getBoundingClientRect();

        let offsetX = e.pageX - (e.pageX - childRect.left);
        let offsetY = e.pageY - (e.pageY - childRect.top) ;

        setMovingEvent({
            lines: [ e.target ],
            offsetX,
            offsetY,
            directions: [ e.target.classList[1] ]
        });
    }

    const createGuide = (direction: Direction, position: string) => {
        let line = document.createElement("div") as HTMLDivElement;
        line.classList.add("dragging", direction, "guide");

        line.style.backgroundColor = "red";
        line.style.pointerEvents = "all";

        if (direction === Direction.HORIZONTAL) {
            line.style.width     = `1px`;
            line.style.height    = `300vw`;
            line.style.left      = `${position}`;
            line.style.top       = `-100vw`;
            line.style.transform = `translate(-50%, 0)`;
        }
        if (direction === Direction.VERTICAL){
            line.style.width     = `300vw`;
            line.style.height    = `1px`;
            line.style.left      = `-100vw`;
            line.style.top       = `${position}`;
            line.style.transform = `translate(0, -50%)`;
        }

        lineContainer.appendChild(line);
        return line;
    }

    const loadGuides = () => {
        project.guides.forEach((guide) => {
            createGuide(guide.direction, guide.position);
        });
        updateProjectGuides();
    }

    const updateProjectGuides = (zoom: number = 1) => {
        let tempGuides : Guide[] = [];
        const child     = children.ref.current as HTMLDivElement;
        const childRect = child.getBoundingClientRect();

        lineContainer.childNodes.forEach((guide : any) => {
            let direction: Direction = guide.classList[1];
            let position = direction === Direction.VERTICAL ? guide.style.top : guide.style.left;
            guide.setAttributeNS("position","position",
                `${(direction === Direction.HORIZONTAL)
                     ? guide.getBoundingClientRect().left - childRect.left
                     : guide.getBoundingClientRect().top - childRect.top}
                 `);
            tempGuides.push({direction, position} as Guide);
        })
        project.guides = tempGuides;
        SubtitleEditorService.updateGuides(! hidden);
    }

    lineContainer.style.visibility = hidden ? "hidden" : "visible";

    useEventListener("mousedown", (e: any) => {
        if (e.target.classList.contains("guide-vertical")) {
            onMouseDown(e, Direction.HORIZONTAL);

        } else if (e.target.classList.contains("guide-horizontal")) {
            onMouseDown(e, Direction.VERTICAL);

        } else if (e.target.classList.contains("guide-combo")) {
            onMouseDown(e, Direction.COMBO);

        } else if (e.target.classList.contains("guide")) {
            moveExistingGuide(e);
        }
    })

    useEventListener("dblclick", (e:any) => {
        if (e.target.classList.contains("guide")) {
            e.target.remove();
            updateProjectGuides();
        }
    });

    useEventListener("resize", () => {
        updateProjectGuides();
    });

    useEffect(() => {
        loadGuides();

        children.ref.current.appendChild(lineContainer);

        return () => {
            project.guides = [];

            Array.from(lineContainer.children).forEach(line => {
                line.remove();
            });
        }
    }, []);

    const horizontalRulerRef = useRef(null);
    const horizontalRulerContainerRef = useRef<HTMLDivElement>();
    const verticalRulerRef = useRef(null);
    const verticalRulerContainerRef = useRef<HTMLDivElement>();

    useEventListener("resize", () => {
        resizeRulers();
    })

    useEffect(() => {
        resizeRulers();
    })

    function resizeRulers(){
        if (horizontalRulerRef.current){
            const offset = (children.ref.current as HTMLElement).getBoundingClientRect().left - horizontalRulerContainerRef.current.getBoundingClientRect().left;
            const segmentWidth = ((children.ref.current as HTMLElement).getBoundingClientRect().width / videoDimensions.x) * 100;
            
            const zoom =  Math.pow(2,Math.floor(35 / segmentWidth))

            horizontalRulerRef.current.changeProperty("segmentWidth", segmentWidth * zoom);
            horizontalRulerRef.current.changeProperty("markings", 100 * zoom);
            
            horizontalRulerRef.current.changeProperty("offset", offset);
            horizontalRulerRef.current.changeProperty("width", wrapRef.current.getBoundingClientRect().width - 32);
        }
        if (verticalRulerRef.current){
            const offset = (children.ref.current as HTMLElement).getBoundingClientRect().top - verticalRulerContainerRef.current.getBoundingClientRect().top;
            const segmentWidth = ((children.ref.current as HTMLElement).getBoundingClientRect().height / videoDimensions.y) * 100;
            
            const zoom =  Math.pow(2,Math.floor(35 / segmentWidth))

            verticalRulerRef.current.changeProperty("segmentWidth", segmentWidth * zoom);
            verticalRulerRef.current.changeProperty("markings", 100 * zoom);
            
            verticalRulerRef.current.changeProperty("offset", offset - 1);
            verticalRulerRef.current.changeProperty("height", wrapRef.current.getBoundingClientRect().height - 32);
        }
    }

    const rulerStyle = {
        mainTick: {
            length: 1,
            width: 1,
            color: {
                activeColor: "#898989",
                inactiveColor: "#454545"
            }
        },
        evenTick: {
            length: 0.20,
            width: 1,
            color: {
                activeColor: "#898989",
                inactiveColor: "#454545"
            }
        },
        oddTick: {
            length: 0.30,
            width: 1,
            color: {
                activeColor: "#898989",
                inactiveColor: "#454545"
            }
        }
    }

    useEffect(() => {
        if (lineContainer){
            Array.from(lineContainer.children).forEach((line, idx) => {
                if (line.classList.contains( Direction.VERTICAL )){
                    (line as HTMLDivElement).style.height = `${1/zoom}px`;
                } else if (line.classList.contains( Direction.VERTICAL )) {
                    (line as HTMLDivElement).style.width  = `${1/zoom}px`;
                }
            })
        }
        updateProjectGuides(zoom);
    }, [zoom])

    return (
        <div ref={wrapRef} className="guide-wrap">
            {relativeCursorPosition && <>
                <div style={{pointerEvents: "none", zIndex: "10000",position: "absolute", color: "red", left: relativeCursorPosition.menuPosition.x, top: relativeCursorPosition.menuPosition.y}}>
                    {relativeCursorPosition.direction === Direction.HORIZONTAL && Math.round(relativeCursorPosition.relativePosition.x)}
                    {relativeCursorPosition.direction === Direction.VERTICAL && Math.round(relativeCursorPosition.relativePosition.y)}
                    {relativeCursorPosition.direction === Direction.COMBO && `${Math.round(relativeCursorPosition.relativePosition.x)}, ${Math.round(relativeCursorPosition.relativePosition.y)}`}
                </div>
            </>}
            { !hidden && 
            <>
                <div ref={horizontalRulerContainerRef} style={{position: "absolute", left: "2em", height: "2em", width: "calc(100% - 2em)", top: "0", zIndex: 1}} className="horizontal-ruler-container guide-horizontal">
                    <Ruler
                        ref={horizontalRulerRef}
                        markings={100}
                        numberOfTicks={10}
                        offset={50}
                        segmentWidth={70}
                        highlight={{
                            from: 0,
                            to: 1920
                        }}
                        height={32}
                        width={1000}
                        style={{
                            pointerEvents: "none",
                            width: "100%"
                        }}
                        tickDesigns={rulerStyle}
                        orientation="horizontal"
                    />
                </div>
                <div ref={verticalRulerContainerRef} style={{position: "absolute", top: "2em", width: "2em", height: "calc(100% - 2em)", left: "0", zIndex: 1}} className="vertical-ruler-container guide-vertical">
                    <Ruler
                        ref={verticalRulerRef}
                        markings={100}
                        numberOfTicks={10}
                        offset={50}
                        segmentWidth={70}
                        highlight={{
                            from: 0,
                            to: 1080
                        }}
                        height={1000}
                        width={32}
                        style={{
                            pointerEvents: "none",
                            height: "100%"
                        }}
                        tickDesigns={rulerStyle}
                        orientation="vertical"
                    />
                </div>
                <div style={{position: "absolute", top: 0, left: 0, width:"2em", height: "2em", backgroundColor:"rgb(48.22, 48.22, 48.22)"}} className="guide-combo"></div>
            </>
            }

            {children}
        </div>
    )
}

export default GuideWrapper;
